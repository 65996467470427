import React from "react";
import {
  FunctionField,
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  SelectInput,
  PasswordInput,
  DeleteButton,
  EditButton,
  ShowButton,
  TopToolbar,
  CreateButton,
  NumberInput,
  ReferenceInput,
  required,
  ReferenceField,
  SaveButton,
  Toolbar,
  FormDataConsumer,
} from "react-admin";
import Actions from "../Components/Actions.js";
import NotFound from "../CustomRoutes/NotFound";

//  --------------------------------------------------------------

const name = "users";
const label = "المستخدمين";

//  --------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const center_id = storage?.center_id || null;
const user_id = storage?.id || null;
const role = storage?.role || null;

//  --------------------------------------------------------------

const mapRole = {
  owner: "مالك",
  admin: "مشرف",
  employee: "موظف",
  student: "طالب",
  teacher: "مدرس",
};

// ---------------------------------------------------------------

const ListUserActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف مستخدم" />
  </TopToolbar>
);

// ---------------------------------------------------------------

const ListPage = (props) => {
  if (role === "owner") {
    return (
      <List
        {...props}
        actions={<ListUserActions />}
        filter={{ role: ["admin", "owner"] }}
      >
        <Datagrid>
          <TextField source="id" label="كود المستخدم" />
          <TextField source="name" label="الاسم" />
          <FunctionField
            label="الوظيفة"
            render={(record) => `${mapRole[record.role] || record.role}`}
          />
          <ReferenceField
            link="show"
            label="الفرع"
            source="center_users[0].branch_id"
            reference="branches"
          >
            <TextField source="name" />
          </ReferenceField>
          <Actions label="الاعدادات">
            <ShowButton label="عرض" />
            <EditButton label="تعديل" />
            <DeleteButton label="حذف" />
          </Actions>
        </Datagrid>
      </List>
    );
  } else {
    return <NotFound />;
  }
};

//  --------------------------------------------------------------

const ShowPage = (props) => {
  if (role === "owner") {
    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="كود المستخدم" />
          <TextField source="name" label="الاسم" />
          <FunctionField
            label="الوظيفة"
            render={(record) => `${mapRole[record.role] || record.role}`}
          />
          <ReferenceField
            link="show"
            label="الفرع"
            source="center_users[0].branch_id"
            reference="branches"
          >
            <TextField source="name" />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    );
  } else {
    return <NotFound />;
  }
};

//  --------------------------------------------------------------

const CreatePage = (props) => {
  if (role === "owner") {
    return (
      <Create
        {...props}
        title="اضافة مستخدم"
        transform={(data) => ({
          ...data,
          center_id,
          password: data.id,
        })}
      >
        <SimpleForm variant="outlined">
          <NumberInput
            source="id"
            label="كود المستخدم"
            validate={required("مطلوب")}
          />
          <TextInput source="name" label="الاسم" validate={required("مطلوب")} />
          <SelectInput
            variant="outlined"
            source="role"
            label="الوظيفة"
            choices={[
              { id: "owner", name: "مالك" },
              { id: "admin", name: "مشرف" },
            ]}
            validate={required("مطلوب")}
          />
          <ReferenceInput
            label="الفرع"
            source="branch_id"
            reference="branches"
            perPage={10000}
            sort={{ field: "name", order: "ASC" }}
            validate={required("مطلوب")}
          >
            <SelectInput optionText={<TextField source="name" />} />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  } else {
    return <NotFound />;
  }
};

//  --------------------------------------------------------------
const confirmPassword = (value, allValues) => {
  if (value !== allValues.new_password) {
    return "كلمة المرور غير مطابقة";
  }
};

const EditPage = (props) => {
  if (role === "owner" && user_id !== props.id) {
    return (
      <Edit {...props} successMessage="تم تغيير البيانات بنجاح" redirect="list">
        <SimpleForm variant="outlined">
          <TextInput source="name" label="الاسم" validate={required("مطلوب")} />
          <SelectInput
            variant="outlined"
            source="role"
            label="الوظيفة"
            choices={[
              { id: "owner", name: "مالك" },
              { id: "admin", name: "مشرف" },
            ]}
            validate={required("مطلوب")}
          />
          <ReferenceInput
            label="الفرع"
            source="center_users[0].branch_id"
            reference="branches"
            perPage={10000}
            sort={{ field: "name", order: "ASC" }}
            validate={required("مطلوب")}
          >
            <SelectInput optionText={<TextField source="name" />} />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  } else if (user_id === props.id) {
    return (
      <Edit
        {...props}
        hasShow={false}
        mutationMode="pessimistic"
        successMessage="تم تغيير كلمة المرور بنجاح"
      >
        <SimpleForm
          successMessage="تم تغيير كلمة المرور بنجاح"
          redirect={false}
          toolbar={
            <Toolbar {...props}>
              <SaveButton />
            </Toolbar>
          }
        >
          <PasswordInput
            label="كلمة المرور القديمة"
            source="old_password"
            variant="outlined"
            validate={required("مطلوب")}
          />
          <PasswordInput
            label="كلمة المرور الجديدة"
            source="new_password"
            variant="outlined"
            validate={required("مطلوب")}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <PasswordInput
                {...rest}
                variant="outlined"
                label="تأكيد كلمة المرور"
                source="confirm_password"
                validate={[confirmPassword, required("مطلوب")]}
              />
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

//  --------------------------------------------------------------

const users = {
  name,
  label,
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

//  --------------------------------------------------------------

export default users;
