import { Show, TextField, SimpleShowLayout } from "react-admin";

import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const ShowPage = (props) => {
  if (role !== "student") {
    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="رقم" />
          <TextField source="name" label="الاسم" />
          <TextField source="level" label="الصف الدراسي" />
        </SimpleShowLayout>
      </Show>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ShowPage;
