import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  NumberInput,
  minValue,
} from "react-admin";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const center_id = storage?.center_id || null;

// ---------------------------------------------------------------

const CreatePage = (props) => (
  <Create
    {...props}
    title="اضافة فرع"
    transform={(data) => ({ center_id, ...data })}
  >
    <SimpleForm variant="outlined" redirect="list">
      <TextInput
        source="name"
        label="اسم الفرع"
        validate={required("اسم الفرع مطلوب")}
      />
      <TextInput
        source="address"
        label="عنوان الفرع"
        validate={required("عنوان الفرع مطلوب")}
      />
      <ArrayInput source="rooms" label="قاعات الفرع">
        <SimpleFormIterator>
          <TextInput
            source="name"
            label="اسم القاعة"
            validate={required("اسم القاعة مطلوب")}
          />
          <NumberInput
            source="max_capacity"
            label="اقصى سعة"
            validate={[required("اقصى سعة مطلوبة"), minValue(0)]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

// ---------------------------------------------------------------

export default CreatePage;
