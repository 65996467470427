import attendances from "./Attendances/attendances.UI.js";
import booklet_instances from "./BookletInstances/bookletInstances.UI.js";
import booklets from "./Booklets/booklets.UI.js";
import branches from "./Branches/branches.UI.js";
import centers from "./Centers/centers.UI.js";
import courseGroups from "./CourseGroups/courseGroups.UI.js";
import courses from "./Courses/courses.UI.js";
import enrolments from "./Enrolments/enrolments.UI.js";
import exams from "./Exams/exams.UI.js";
import flow from "./Flow/flow.UI.js";
import rooms from "./Rooms/rooms.UI.js";
import students from "./Students/students.UI.js";
import teachers from "./Teachers/teachers.UI.js";
import scores from "./Scores/scores.UI.js";
import timetables from "./TimeTables/timetables.UI.js";
import centerProfile from "../CustomResources/CenterProfile";
import users from "./users.ui.js";

//  --------------------------------------------------------------

const entities = [
  students,
  flow,
  attendances,
  users,
  courses,
  teachers,
  courseGroups,
  centers,
  branches,
  rooms,
  enrolments,
  centerProfile,
  booklets,
  booklet_instances,
  exams,
  scores,
  timetables,
];

//  --------------------------------------------------------------

export default entities;
