import React from "react";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const center_id = storage?.center_id || null;
let center = JSON.parse(localStorage.getItem("center"));

// ---------------------------------------------------------------

const name = "centerProfile";
const label = " 2 الفروع";

const uploadURL = process.env.REACT_APP_API_URL + "upload";
const assetURL = process.env.REACT_APP_API_URL + "assets/";

// ---------------------------------------------------------------

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: "50px",
  },
  input: {
    margin: "10px",
  },
  img: {
    display: "flex",
    border: "1px solid grey",
    borderRadius: "10px",
    width: "100px",
    height: "100px",
    margin: "10px 50px",
  },
  imgLabel: {
    padding: "50px",
  },
  btn: {
    margin: "50px 0 0 0",
    padding: "10px",
  },
};

// ---------------------------------------------------------------

const Input = (props) => (
  <TextField
    id={props.name}
    style={styles.input}
    variant="outlined"
    {...props}
  />
);

// ---------------------------------------------------------------

const InputImage = (props) => {
  const pickImage = (e) => {
    const img = document.getElementById("img-logo");
    img.src = URL.createObjectURL(e.target.files[0]);
  };

  return (
    <div>
      <label style={styles.imgLabel}>
        الشعار
        <img
          id="img-logo"
          src={`${assetURL}/${center_id}`}
          style={styles.img}
          alt="center-profile"
        />
      </label>
      <label for="img-upload">
        تغير صورة الشعار
        <input
          id="img-upload"
          accept="image/*"
          placholder="choose leda"
          type="file"
          onChange={pickImage}
        />
      </label>
    </div>
  );
};
// ---------------------------------------------------------------

const ListPage = () => {
  const inputs = ["name", "owner_name", "owner_phone", "balance"];
  const uploadImage = async (e) => {
    e.preventDefault();
    let values = {};
    inputs.forEach((id) => {
      let el = document.getElementById(id);
      if (el?.value) values[id] = el.value;
    });

    const imageFile = document.getElementById("img-upload");
    const img = imageFile.files[0];
    const formdata = new FormData();

    if (img) {
      formdata.append("avatar", img, center_id);
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      try {
        const res = await fetch(uploadURL, requestOptions);
        const data = await res.json();
        if (data) values.logo_url = data?.url;
      } catch (err) {
        console.error("error in upload image");
        console.error(e.message);
        console.error(e);
      }
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(values);

    var requestOptions2 = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_URL + "/centers/1", requestOptions2)
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem("center", JSON.stringify(result));
        window.location.reload();
      })
      .catch((error) => console.error("error", error));
  };

  return (
    <Card>
      <form style={styles.form}>
        <Input name="name" label="اسم المركز" defaultValue={center?.name} />
        <Input
          name="owner_name"
          label="اسم مالك المركز"
          defaultValue={center?.owner_name}
        />
        <Input
          name="owner_phone"
          label="الهاتف"
          type="number"
          defaultValue={center?.owner_phone}
        />
        {/* <Input
          name="balance"
          label="رصيد المالك"
          type="number"
          defaultValue={center?.balance}
          disabled
        />*/}
        {/* <Input name="logo_url" label="name" type="file" /> */}

        <InputImage />
        <button style={styles.btn} onClick={uploadImage}>
          حفظ التغيرات
        </button>
      </form>
    </Card>
  );
};

// ---------------------------------------------------------------

const centerProfile = {
  name,
  label,
  list: ListPage,
};

// ---------------------------------------------------------------

export default centerProfile;
