import ShowPage from "./courseGroups.Show";
import ListPage from "./courseGroups.List";
import CreatePage from "./courseGroups.Create";
import EditPage from "./courseGroups.Edit";

// ---------------------------------------------------------------

const courseGroups = {
  name: "course_groups",
  label: "المجموعات",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default courseGroups;
