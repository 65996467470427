import {
  TextField,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  minValue,
  ReferenceInput,
  SelectInput,
} from "react-admin";

// ---------------------------------------------------------------

const CreatePage = (props) => (
  <Create {...props} title="اضافة قاعة">
    <SimpleForm variant="outlined" redirect="list">
      <ReferenceInput
        label="الفرع"
        source="branch_id"
        reference="branches"
        perPage={10000}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          optionText={<TextField source="name" />}
          validate={required("الفرع مطلوب")}
        />
      </ReferenceInput>
      <TextInput
        source="name"
        label="اسم القاعة"
        validate={required("اسم القاعة مطلوب")}
      />
      <NumberInput
        source="max_capacity"
        label="اقصى سعة"
        validate={[required("اقصى سعة مطلوبة"), minValue(0)]}
      />
    </SimpleForm>
  </Create>
);

// ---------------------------------------------------------------

export default CreatePage;
