import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from "react-admin";
import { levels } from "../../Utils/selectValues.js";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  if (role !== "student") {
    return (
      <Create
        {...props}
        title="اضافة مادة"
        transform={(data) => ({ ...data })}
        successMessage="تم إضافة المادة بنجاح"
      >
        <SimpleForm variant="outlined" redirect="list">
          <TextInput
            source="name"
            label="الاسم"
            validate={required("اسم المادة مطلوب")}
          />
          <SelectInput
            source="level"
            label="الصف الدراسي"
            choices={levels}
            validate={required("الصف الدراسي مطلوب")}
          />
        </SimpleForm>
      </Create>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default CreatePage;
