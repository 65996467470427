import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  NumberInput,
  email,
} from "react-admin";
import { levels } from "../../Utils/selectValues.js";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const center_id = storage?.center_id || null;
const branch_id = storage?.branch_id || null;

// ---------------------------------------------------------------

const CreatePage = (props) => (
  <Create
    {...props}
    title="اضافة طالب"
    transform={(data) => ({
      ...data,
      branch_id,
      center_id,
      role: "student",
      password: data.id,
      active: false,
    })}
    successMessage="تم تسجيل الطالب بنجاح"
  >
    <SimpleForm variant="outlined" redirect={"/students"}>
      <NumberInput
        source="id"
        label="كود الطالب"
        validate={required("مطلوب")}
      />
      <TextInput source="name" label="الاسم" validate={required("مطلوب")} />
      <TextInput
        source="email"
        label="البريد الإلكتروني"
        //validate={[required("مطلوب"), email('برجاء ادخال بريد إلكتروني صالح')]}
      />
      <TextInput
        source="phone"
        label="الهاتف"
        validate={[/*validPhone,*/ required("مطلوب")]}
      />
      <TextInput
        source="parent_phone"
        label="هاتف ولى الامر"
        /*validate={validPhone}*/
      />
      <TextInput source="whats_app" label="واتس اب" /*validate={validPhone}*/ />
      <TextInput
        source="vodafone_cash"
        label="فودافون كاش"
        /*validate={validPhone}*/
      />
      <SelectInput
        source="level"
        label="الصف الدراسي"
        choices={levels}
        validate={required("مطلوب")}
      />
    </SimpleForm>
  </Create>
);

// ---------------------------------------------------------------

export default CreatePage;
