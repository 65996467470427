import {
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Button from "@mui/material/Button";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

// const MyShow = (props) => {
//   const {
//     basePath, // deduced from the location, useful for action buttons
//     defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
//     error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onFailure` side effect.
//     loaded, // boolean that is false until the record is available
//     loading, // boolean that is true on mount, and false once the record was fetched
//     record, // record fetched via dataProvider.getOne() based on the id from the location
//     resource, // the resource name, deduced from the location. e.g. 'posts'
//     version, // integer used by the refresh feature
//   } = useShowController(props);
//   return (
//     <div>
//       <h2>Diamond Center</h2>
//       {cloneElement(props.children, {
//         basePath,
//         record,
//         resource,
//         version,
//       })}
//     </div>
//   );
// };

// ---------------------------------------------------------------
const printReceipt = () => {
  var printContents = document.getElementById("print").innerHTML;
  var originalContents = document.body.innerHTML;

  document.body.innerHTML = printContents;
  // document.body.style.setProperty("font-weight", "bold");

  window.print();

  document.body.innerHTML = originalContents;

  window.location.reload();

  //   var mywindow = window.open('', 'PRINT', 'height=400,width=600');

  // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
  // mywindow.document.write('</head><body >');
  // mywindow.document.write('<h1>' + document.title  + '</h1>');
  // mywindow.document.write(document.getElementById("print").innerHTML);
  // mywindow.document.write('</body></html>');

  // mywindow.document.close(); // necessary for IE >= 10
  // mywindow.focus(); // necessary for IE >= 10*/

  // mywindow.print();
  // mywindow.close();

  // return true;
};

//-----------------------------------------
const ShowPage = (props) => {
  if (role !== "student") {
    return (
      <>
        <div style={{ "margin-right": 20 }}>
          <Button
            variant="outlined"
            startIcon={<ReceiptLongIcon />}
            sx={{ width: 100, margin: 2, marginRight: 10 }}
            size="large"
            onClick={printReceipt}
          >
            طباعة إيصال
          </Button>
        </div>
        <div style={{ width: "80mm", "text-color": "red" }}>
          <Show {...props}>
            <div id="print">
              {/* <img src="../../../public/images/center_logo/logo.jpg" alt="asd" /> */}
              <SimpleShowLayout>
                <TextField
                  source="id"
                  label="كود العملية"
                  sx={{ color: "red" }}
                />
                <FunctionField
                  label="التاريخ"
                  source="created_at"
                  render={(record) =>
                    new Date(record.created_at).toLocaleDateString()
                  }
                />
                <FunctionField
                  label="الوقت"
                  source="created_at"
                  render={(record) =>
                    new Date(record.created_at).toLocaleTimeString()
                  }
                />
                {role !== "student" && (
                  <ReferenceField
                    label="الفرع "
                    source="branch_id"
                    reference="branches"
                    link={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                )}
                {role !== "student" && (
                  <ReferenceField
                    label="الطالب"
                    source="from_id"
                    reference="users"
                    link={false}
                    range={[0, 1000]}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                )}
                {role !== "student" && (
                  <ReferenceField
                    label="الموظف المسؤول"
                    source="to_id"
                    reference="users"
                    link={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                )}
                {role !== "student" && (
                  <TextField source="type" label="نوع العملية" />
                )}
                <NumberField
                  source="amount"
                  label="المبلغ"
                  options={{ style: "currency", currency: "EGP" }}
                />
                <TextField source="description" label="الوصف" />
              </SimpleShowLayout>
            </div>
          </Show>
        </div>
      </>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ShowPage;
