import ListPage from "./timetable.Edit";
import CreatePage from "./timetables.Create";
import EditPage from "./timetable.Edit";
import ShowPage from "./timetable.Show";

// ---------------------------------------------------------------

const timetables = {
  name: "timetables",
  label: "المواعيد",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default timetables;
