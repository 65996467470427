import { useState, useEffect } from "react";
import { getTimetable } from "./api.queries";

// =================================================

const apiQuerires = {
    getTimetable,
};

// =================================================

export default function useMyQuery({ queryName }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    // ----------------------------

    useEffect(() => {
        (async () => {
            setLoading(false);
            const resData = await apiQuerires[queryName]();
            setData(resData);
            setLoading(true);
        })();
    }, [queryName]);

    // ----------------------------

    return {
        loading,
        data,
    };
}
