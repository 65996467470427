import {
  Create,
  TextInput,
  required,
  AutocompleteInput,
  ReferenceInput,
  TabbedForm,
  FormTab,
  SelectInput,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";
import { validMoney } from "../../Utils/validations.js";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const role = storage?.role || null;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  if (role !== "student") {
    return (
      <Create
        {...props}
        title="مدفوعات الطالب"
        successMessage="تم تسجيل العملية بنجاح"
        transform={(data) => ({
          ...data,
          deposit: data.deposit
            ? `إيداع في محفظة الطالب  (  ${data.deposit} )`
            : "إيداع في محفظة الطالب",
          withdraw: data.withdraw
            ? `سحب من محفظة الطالب  ( ${data.withdraw}  )`
            : "سحب من محفظة الطالب",
          branch_id,
        })}
      >
        <TabbedForm redirect="show" variant="outlined" syncWithLocation={false}>
          <FormTab label="ايداع">
            <SelectInput
              label="الموظف المسؤل"
              source="employee"
              choices={[{ id: storage.id, name: storage.name }]}
              initialValue={storage.id}
              optionText="name"
              disabled
            />
            <ReferenceInput
              label="الطالب"
              source="student"
              reference="students"
              perPage={10}
              sort={{ field: "name", order: "ASC" }}
            >
              <AutocompleteInput
                optionText={(choice) => `${choice.id} - ${choice.name}`}
                validate={required("مطلوب")}
              />
            </ReferenceInput>
            <TextInput
              source="deposit_amount"
              label="المبلغ"
              validate={validMoney}
            />
            <TextInput source="deposit" label="الوصف" />
          </FormTab>
          <FormTab label="سحب">
            <SelectInput
              label="الموظف المسؤل"
              source="employee"
              choices={[{ id: storage.id, name: storage.name }]}
              initialValue={storage.id}
              optionText="name"
              disabled
            />
            <ReferenceInput
              label="الطالب"
              source="student"
              reference="students"
              perPage={10}
              sort={{ field: "name", order: "ASC" }}
            >
              <AutocompleteInput
                optionText={(choice) => `${choice.id} - ${choice.name}`}
                validate={required("مطلوب")}
              />
            </ReferenceInput>
            <TextInput
              source="withdrawal_amount"
              label="المبلغ"
              validate={validMoney}
            />
            <TextInput source="withdraw" label="الوصف" />
          </FormTab>
        </TabbedForm>
      </Create>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default CreatePage;
