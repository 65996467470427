import ListPage from "./teachers.List";
import ShowPage from "./teachers.Show";
import CreatePage from "./teachers.Create";
import EditPage from "./teachers.Edit";

// ---------------------------------------------------------------

const teachers = {
  name: "teachers",
  label: "المدرسين",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default teachers;
