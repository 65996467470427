import React, {
  createElement,
  useRef,
  useEffect,
  useState,
  useContext,
} from "react";
import classnames from "classnames";
import { Card, Avatar, TextField, Button, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
// import { useHistory } from "react-router-dom";
import { useLogin, useCheckAuth } from "react-admin";
import roleContext from "../Providers/roleContext";
import userContext from "../Providers/user.context.js";

// ====================================================================

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    card: {
      minWidth: 450,
      marginTop: "1em",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: "2em",
    },
    input: {
      width: 370,
      margin: "10px 0px",
    },
    button: {
      height: "50px",
      margin: "35px 0px 0px 0px",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: "RaLogin" }
);

// ====================================================================

export default function Signup(props) {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    staticContext,
    backgroundImage,
    ...rest
  } = props;

  // ----------------------------------------------------

  const containerRef = useRef();
  const classes = useStyles(props);
  const checkAuth = useCheckAuth();
  // const history = useHistory();
  const login = useLogin();

  let backgroundImageLoaded = false;

  // ----------------------------------------------------

  const { setRole } = useContext(roleContext);
  const { setUser } = useContext(userContext);

  // ----------------------------------------------------

  const [values, setValues] = useState({
    IDBObjectStore: "",
    password: "",
  });

  // ----------------------------------------------------

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // ----------------------------------------------------

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  // ----------------------------------------------------

  // window.location.href = "/#/"
  useEffect(() => {
    checkAuth()
      .then((user) => {
        if (user) {
          window.location.href = "/";
          setUser(user);
        }
      })
      .catch();
  }, [checkAuth, setUser]);

  // ----------------------------------------------------

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  // ----------------------------------------------------

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  // ----------------------------------------------------

  const onSubmit = async (e) => {
    e.preventDefault();
    await login(values)
      .then((role) => {
        setRole(role);
        window.location.href = "/";
      })
      .catch((e) => console.error("login failed ! ", e));
  };

  // ----------------------------------------------------

  return (
    <div
      className={classnames(classes.main, className)}
      {...rest}
      ref={containerRef}
    >
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <h2>تسجيل دخول</h2>

        {/* ======================================================= */}

        <form className={classes.form} onSubmit={onSubmit}>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            كود المستخدم
            <TextField
              name="id"
              onChange={handleChange}
              value={values.id}
              className={classes.input}
              required
            />
          </InputLabel>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            كلمه السر
            <TextField
              type="password"
              name="password"
              onChange={handleChange}
              value={values.password}
              className={classes.input}
              required
            />
          </InputLabel>
          {/*<div className={classes.input}>
            <a href="/#/signup">ليس لديك حساب؟</a>
          </div>*/}
          {/* ------------------------------------------ */}
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
          >
            تسجيل
          </Button>
        </form>
      </Card>
      {notification ? createElement(notification) : null}
    </div>
  );
}
