import ListPage from "./rooms.List";
import ShowPage from "./rooms.Show";
import CreatePage from "./rooms.Create";
import EditPage from "./rooms.Edit";

// ---------------------------------------------------------------

const rooms = {
  name: "rooms",
  label: "القاعات",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default rooms;
