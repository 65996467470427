import {
  Create,
  SimpleForm,
  required,
  SelectInput,
  ReferenceInput,
  FunctionField,
  NumberInput,
  AutocompleteInput,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  if (role !== "student") {
    return (
      <Create
        {...props}
        title="اضافة مادة"
        transform={(data) => ({ ...data })}
        successMessage="تم إضافة درجة الطالب بنجاح"
      >
        <SimpleForm variant="outlined" redirect={false}>
          <ReferenceInput
            label="الطالب"
            source="student_id"
            reference="students"
            perPage={10}
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput
              optionText={(choice) => `${choice.id} - ${choice.name}`}
              validate={required("مطلوب")}
            />
          </ReferenceInput>
          <ReferenceInput
            label="الامتحان"
            source="exam_id"
            reference="exams"
            filter={
              role === "teacher"
                ? { teacher_id: user_id }
                : role === "student"
                ? { student_id: user_id }
                : false
            }
            perPage={10000}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              validate={required("مطلوب")}
              optionText={
                <FunctionField
                  render={(record) =>
                    `${record.name} - ${record.total_marks} درجة`
                  }
                />
              }
            />
          </ReferenceInput>
          <NumberInput source="marks" label="الدرجة" />
        </SimpleForm>
      </Create>
    );
  } else {
    return <NotFound />;
  }
};
// ---------------------------------------------------------------

export default CreatePage;
