import React, { useState } from "react";
import { MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import SubMenu from "./MySubMenu";

// Icons
import SettingsIcon from "@material-ui/icons/Settings";
import QueueRoundedIcon from "@material-ui/icons/QueueRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import HelpOutline from "@material-ui/icons/HelpOutline";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

// =================================================================

const storage = JSON.parse(localStorage?.getItem("user"));
const user_id = storage?.id || null;

// =================================================================

export default function TeacherMenu({ dense = false, onMenuClick }) {
  const classes = useStyles();

  //  ---------------------------------------

  const [state, setState] = useState({
    studentMenu: false,
    bookletsMenu: false,
    examsMenu: false,
  });

  //  ---------------------------------------

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  //  ---------------------------------------

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  //  ---------------------------------------

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {/* ------------------------------------------------- */}

      <SubMenu
        handleToggle={() => handleToggle("examsMenu")}
        isOpen={state.examsMenu}
        name="الامتحانات والتقييم"
        icon={<HelpOutline />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/exams/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"اضافة امتحان"}
          leftIcon={<QueueRoundedIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/exams",
            state: { _scrollToTop: true },
          }}
          primaryText={"بيانات الامتحانات"}
          leftIcon={<Assignment />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/scores/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"تصحيح امتحان"}
          leftIcon={<AssignmentTurnedIn />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/scores",
            state: { _scrollToTop: true },
          }}
          primaryText={"درجات الامتحانات"}
          leftIcon={<ListAltRoundedIcon />}
          dense={dense}
        />
      </SubMenu>

      {/* ------------------------------------------------- */}

      <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        name="الاعدادات"
        icon={<SettingsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/users/" + user_id,
            state: { _scrollToTop: true },
          }}
          primaryText={"تغيير كلمة المرور"}
          customResources
          dense={dense}
          leftIcon={<VisibilityOffIcon />}
        />
      </SubMenu>
    </div>
  );
}

// =================================================================

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

// =================================================================
