import * as React from "react";

import { Sidebar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MyMenu from "./MyMenu";
// ---------------------------------------------------

const useSidebarStyles = makeStyles({
  drawerPaper: {
    // backgroundColor: "#2196f3",
  },
});

// ---------------------------------------------------

export default function MySidebar(props) {
  const classes = useSidebarStyles();
  return (
    <Sidebar classes={classes} {...props}>
      <MyMenu />
    </Sidebar>
  );
}
