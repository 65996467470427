import {
  Edit,
  SimpleForm,
  required,
  SelectInput,
  ReferenceInput,
  FunctionField,
  NumberInput,
} from "react-admin";

import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const EditPage = (props) => {
  if (role !== "student") {
    return (
      <Edit
        {...props}
        transform={(data) => ({ ...data })}
        successMessage="تم تعديل البيانات بنجاح"
      >
        <SimpleForm variant="outlined">
          <ReferenceInput
            label="الطالب"
            source="student_id"
            reference="students"
            perPage={10000}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              validate={required("مطلوب")}
              optionText={
                <FunctionField
                  render={(record) => `${record.id} - ${record.name}`}
                />
              }
            />
          </ReferenceInput>
          <ReferenceInput
            label="الامتحان"
            source="exam_id"
            reference="exams"
            perPage={10000}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              validate={required("مطلوب")}
              optionText={
                <FunctionField
                  render={(record) =>
                    `${record.name} - ${record.total_marks} درجة`
                  }
                />
              }
            />
          </ReferenceInput>
          <NumberInput source="marks" label="الدرجة" />
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default EditPage;
