import ListPage from "./bookletInstances.List";
import CreatePage from "./bookletInstances.Create";
import ShowPage from "./bookletInstances.Show";
import EditPage from "./bookletInstances.Edit";

// ------------------------------------------

const bookletIntances = {
  name: "booklet_instances",
  label: "المذكرات",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ------------------------------------------

export default bookletIntances;
