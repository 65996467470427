import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
  FunctionField,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const ShowPage = (props) => {
  if (role !== "student") {
    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="رقم" />
          <ReferenceField
            link="show"
            label="الطالب"
            source="student_id"
            reference="students"
          >
            <FunctionField
              render={(record) => `${record.id} - ${record.name}`}
            />
          </ReferenceField>
          <ReferenceField
            link="show"
            label="الامتحان"
            source="exam_id"
            reference="exams"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="marks" label="الدرجة" />
          <ReferenceField
            link="show"
            label="الدرجة العظمى"
            source="exam_id"
            reference="exams"
          >
            <FunctionField render={(record) => `${record.total_marks}`} />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ShowPage;
