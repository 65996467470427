import API from "./api.config.js";

// ----------------------------------------------------

export async function entityFindAll(entity) {
  try {
    const res = await API.get("/" + entity);
    const data = await res.data;
    return data;
  } catch (e) {
    console.error("error in find all entity:", entity);
    console.error("error message: ", e.message);
    console.error("error: ", e);
  }
}

// ----------------------------------------------------

export async function entityFindOne(entity, id) {
  try {
    const res = await API.get("/" + entity + "/" + id);
    const data = await res.data;
    return data;
  } catch (e) {
    console.error("error in find one entity:", entity);
    console.error("error message: ", e.message);
    console.error("error: ", e);
  }
}

// ----------------------------------------------------
export async function entityCreate(entity, body) {
  try {
    const res = await API.post("/" + entity, body);
    const data = await res.data;
    return data;
  } catch (e) {
    console.error("error in create entity:", entity);
    console.error("error message: ", e.message);
    console.error("error: ", e);
  }
}

// ----------------------------------------------------

export async function entityUpdate(entity, body) {
  try {
    const res = await API.put("/" + entity + "/" + body);
    const data = await res.data;
    return data;
  } catch (e) {
    console.error("error in update entity:", entity);
    console.error("error message: ", e.message);
    console.error("error: ", e);
  }
}

// ----------------------------------------------------

export async function getTimetable() {
  try {
    const res = await API.get("/timetable");
    const data = await res.data;
    return data;
  } catch (error) {
    console.error("error in get timetable");
    console.error("error message: ", error.message);
    console.error("error: ", error);
    return error;
  }
}


// ----------------------------------------------------

