import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
} from "react-admin";
import { validPhone } from "../../Utils/validations.js";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const role = storage?.role || null;

// ---------------------------------------------------------------

const EditPage = (props) => {
  if (role !== "student") {
    return (
      <Edit
        {...props}
        transform={(data) => ({ ...data, branch_id })}
        successMessage="تم تعديل البيانات بنجاح"
      >
        <SimpleForm variant="outlined" redirect="list">
          <NumberInput
            source="id"
            label="كود المدرس"
            validate={required("مطلوب")}
          />
          <TextInput source="phone" label="الهاتف" validate={validPhone} />
          <TextInput
            source="speciality"
            label="االتخصص"
            validate={required("التخصص مطلوب")}
          />
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default EditPage;
