import ListPage from "./attendances.List";
import CreatePage from "./attendances.Create";

// ------------------------------------------

const attendances = {
  name: "attendances",
  label: "الحضور",
  list: ListPage,
  create: CreatePage,
};

// ------------------------------------------

export default attendances;
