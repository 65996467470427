import StudentMenu from "./StudentMenu";
import OwnerAdminMenu from "./OwnerAdminMenu";
import TeacherMenu from "./TeacherMenu";

// =================================================================

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// =================================================================

export default function MyMenu() {
  if (role === "owner" || role === "admin") {
    return <OwnerAdminMenu />;
  } else if (role === "student") {
    return <StudentMenu />;
  } else if (role === "teacher") {
    return <TeacherMenu />;
  }
}
