import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  TopToolbar,
  ReferenceField,
  FunctionField,
  NumberField,
  TextInput,
} from "react-admin";
import Actions from "../../Components/Actions.js";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const filters = [
  <TextInput source="q" label="بحث" alwaysOn />,

  /*<SelectInput
    label="التسوية"
    source="settelled_accounting"
    choices={[
      { id: true, name: "تم التسوية" },
      { id: false, name: "لم يتم التسوية" },
    ]}
  />,*/
];

// ---------------------------------------------------------------

const GroupsAction = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف مجموعة" />
    <ExportButton label="تصدير" />
  </TopToolbar>
);

// ---------------------------------------------------------------

const ListPage = (props) => {
  if (role !== "student") {
    return (
      <List
        {...props}
        actions={<GroupsAction />}
        bulkActionButtons={false}
        filters={filters}
        filter={{active:1}}
      >
        <Datagrid>
          <TextField source="id" label="كود" />
          <TextField source="name" label="اسم المجموعة" />
          <ReferenceField
            label="المدرس"
            source="teacher_id"
            reference="teachers"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="المادة الدراسية"
            source="course_id"
            reference="courses"
            link="show"
          >
            <FunctionField
              render={(record) => `${record.name} - ${record.level}`}
            />
          </ReferenceField>
          <ReferenceField
            label="الفرع "
            source="branch_id"
            reference="branches"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="payment_type" label="نوع الدفع" />
          <NumberField
            source="cost"
            label="التكلفة"
            options={{ style: "currency", currency: "EGP" }}
          />
          <TextField source="active" label="مفعل" />
          <Actions label="الاعدادات">
            <ShowButton label="عرض" />
            <EditButton label="تعديل" />
            {/*<DeleteButton label="حذف" />*/}
          </Actions>
        </Datagrid>
      </List>
    );
  } else {
    return <NotFound />;
  }
};
// ---------------------------------------------------------------

export default ListPage;
