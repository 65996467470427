import { useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  FunctionField,
  ReferenceInput,
  FormDataConsumer,
  BooleanInput,
  AutocompleteInput,
  useMutation,
} from "react-admin";
import { validMoney } from "../../Utils/validations.js";
import NotFound from "../../CustomRoutes/NotFound";
import { toast } from "react-toastify";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const user_id = storage?.id || null;
const role = storage?.role || null;
const center_id = JSON.parse(localStorage?.getItem("center"))?.id;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  const [mutate, { data }] = useMutation();

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  const submitHanlder = async (values) => {
    const payload = { data: { ...values, branch_id, user_id, center_id } };
    const mutatePayload = { type: "create", resource: "attendances", payload };
    try {
      await mutate(mutatePayload, { returnPromise: true });
    } catch (error) {
      console.error({ error });
      return error;
    }
  };

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  useEffect(() => {
    if (data) {
      toast.success(`تم تسجيل الحضور بنجاح`, { theme: "dark" });
    }
  }, [data]);

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  if (role !== "owner" && role !== "admin") return <NotFound />;

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  return (
    <Create {...props} title="تسجيل الحضور">
      <SimpleForm variant="outlined" save={submitHanlder}>
        <ReferenceInput
          label="المدرس"
          source="teacher_id"
          reference="teachers"
          perPage={10}
          sort={{ field: "name", order: "ASC" }}
          defaultValue={props?.data?.teacher_id}
          validate={required("مطلوب")}
        >
          <AutocompleteInput
            optionText={(choice) =>
              `${choice.name} - ${choice.teacher.speciality}`
            }
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.teacher_id !== undefined && (
              <ReferenceInput
                label="المجموعة"
                source="group_id"
                reference="course_groups"
                filter={{ teacher_id: formData.teacher_id, active: 1 }}
                {...rest}
                perPage={10}
                sort={{ field: "name", order: "ASC" }}
                validate={required("مطلوب")}
              >
                <AutocompleteInput
                  optionText={(choice) =>
                    `${choice.name} - ${choice.number_of_students} طالب`
                  }
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.group_id !== undefined && (
              <ReferenceInput
                label="التكلفة"
                source="group_id"
                reference="course_groups"
                filter={{ id: formData.group_id }}
                {...rest}
                disabled
              >
                <SelectInput
                  optionText={
                    <FunctionField
                      render={(record) =>
                        `(${record.cost} ج.م. / ${record.payment_type}) + (${record.center_cost_per_lecture} ج.م. سنتر)`
                      }
                    />
                  }
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.group_id !== undefined && (
              <ReferenceInput
                label="الميعاد"
                source="timetable_id"
                reference="timetables"
                filter={{ group_id: formData.group_id }}
                {...rest}
                perPage={10000}
              >
                <SelectInput
                  validate={required("مطلوب")}
                  optionText={
                    <FunctionField
                      render={(record) =>
                        `${record.day} - ${new Date(
                          record.from
                        ).toLocaleTimeString()}`
                      }
                    />
                  }
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          label="الطالب"
          source="student_id"
          reference="students"
          perPage={10}
          sort={{ field: "name", order: "ASC" }}
          validate={required("مطلوب")}
        >
          <AutocompleteInput
            optionText={(choice) => `${choice.id} - ${choice.name}`}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.student_id !== undefined && (
              <ReferenceInput
                label="رصيد الطالب"
                source="student_id"
                reference="students"
                filter={{ id: formData.student_id }}
                {...rest}
                disabled
              >
                <SelectInput
                  optionText={
                    <FunctionField
                      render={(record) => `${record.student.balance} ج.م.`}
                    />
                  }
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <TextInput
          source="payment"
          label="المبلغ المدفوع"
          validate={validMoney}
        />
        <BooleanInput label="الواجب" source="home_work" />
        <TextInput source="home_work_notes" label="ملاحظات الواجب" />
      </SimpleForm>
    </Create>
  );
};

// ---------------------------------------------------------------

export default CreatePage;
