import { useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  required,
  RadioButtonGroupInput,
  FormDataConsumer,
  AutocompleteInput,
  useMutation,
  useRedirect,
} from "react-admin";
import { validMoney } from "../../Utils/validations.js";
import { toast } from "react-toastify";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  const [mutate, { data }] = useMutation();
  const redirect = useRedirect();

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  const submitHanlder = async (values) => {
    const payload = { data: { ...values, branch_id, user_id } };
    const mutatePayload = { type: "create", resource: "enrolments", payload };
    try {
      await mutate(mutatePayload, { returnPromise: true });
    } catch (error) {
      console.error({ error });
      return error;
    }
  };

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  useEffect(() => {
    if (data) {
      toast.success(`تم تسجيل الطالب بنجاح`, { theme: "dark" });
      if (data?.id) {
        redirect(`/flow/${data?.id}/show`, data);
      }
    }
  }, [data, redirect]);

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  return (
    <Create {...props} title="تسجيل طالب في مجموعة">
      <SimpleForm variant="outlined" save={submitHanlder}>
        <ReferenceInput
          label="الطالب"
          source="student_id"
          reference="students"
          perPage={10}
          sort={{ field: "name", order: "ASC" }}
          validate={required("مطلوب")}
        >
          <AutocompleteInput
            optionText={(choice) => `${choice.id} - ${choice.name}`}
          />
        </ReferenceInput>
        <ReferenceInput
          label="المدرس"
          source="teacher_id"
          reference="teachers"
          perPage={10}
          sort={{ field: "name", order: "ASC" }}
          validate={required("مطلوب")}
        >
          <AutocompleteInput
            optionText={(choice) =>
              `${choice.name} - ${choice.teacher.speciality}`
            }
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.teacher_id !== undefined && (
              <ReferenceInput
                label="المجموعة"
                source="group_id"
                reference="course_groups"
                filter={{ teacher_id: formData.teacher_id, active: 1 }}
                {...rest}
                perPage={10}
                sort={{ field: "name", order: "ASC" }}
                validate={required("مطلوب")}
              >
                <AutocompleteInput
                  optionText={(choice) =>
                    `${choice.name} - ${choice.number_of_students} طالب`
                  }
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <TextInput source="balance" label="مبلغ الحجز" validate={validMoney} />
        <RadioButtonGroupInput
          source="payment_type"
          label="طالب"
          defaultValue="عادي"
          choices={[
            { id: "عادي", name: "عادي" },
            { id: "خاص", name: "خاص" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.payment_type === "خاص" && (
              <TextInput
                {...rest}
                source="lecture_cost"
                label="تكلفة الحصة"
                validate={[validMoney, required("مطلوب")]}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.payment_type === "خاص" && (
              <TextInput
                {...rest}
                source="center_cost_per_lecture"
                label="تكلفة السنتر"
                validate={[validMoney, required("مطلوب")]}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

// ---------------------------------------------------------------

export default CreatePage;
