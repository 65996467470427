import {
  Show,
  NumberField,
  SimpleShowLayout,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

// =========================================================================

const useStyles = makeStyles({
  price: { color: "dodgerblue", fontWeight: "bold", fontSize: "40px" },
});

const ShowPage = (props) => {
  const classes = useStyles();
  return (
    <Show {...props} hasEdit={false}>
      <SimpleShowLayout>
        <NumberField
          source="balance"
          label={false}
          options={{ style: "currency", currency: "EGP" }}
          className={classes.price}
        />
        {/*<TextField source="name" label="اسم المركز" />
      <TextField source="owner_name" label="المالك" />
      <TextField source="owner_phone" label="رقم المالك" />
      <NumberField source="number_of_branches" label="عدد الفروع" />
      
      <FunctionField
        label="الشعار"
        render={(record) => (
          <img
            src={`http://localhost:5000/assets/${record?.id}`}
            alt="center logo"
            width="50"
            height="50"
          />
        )}
      />*/}
      </SimpleShowLayout>
    </Show>
  );
};

// =========================================================================

export default ShowPage;
