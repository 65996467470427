import { createTheme } from "@material-ui/core/styles";
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";

const myTheme = createTheme(
  merge(defaultTheme, {
    direction: "rtl",
    palette: {
      primary: indigo,
      secondary: pink,
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    sidebar: {
      background: red,
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    root: {
      background: "red",
    },
    overrides: {
      root: {
        background: "red",
      },
      MuiButton: {
        root: {
          color: "red",
        },
      },
    },
  })
);

// const myTheme =
export default myTheme;
