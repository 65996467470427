//import ListPage from "./centers.List";
//import CreatePage from "./centers.Create";
//import EditPage from "./centers.Edit";
import ShowPage from "./centers.Show";

// =========================================================================

const courses = {
  name: "centers",
  label: "الرصيد",
  //list: ListPage,
  show: ShowPage,
  //create: CreatePage,
  //edit: EditPage,
};

// =========================================================================

export default courses;
