import React, { useState } from "react";
import MyAdmin from "./Components/MyAdmin";
import roleContext from "./Providers/roleContext";
import userContext from "./Providers/user.context";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ===========================================================================

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// ===========================================================================

export default function App() {
  const [role, setRole] = useState(null);
  const [user, setUser] = useState(null);

  return (
    <>
      <StylesProvider jss={jss}>
        <userContext.Provider value={{ user, setUser }}>
          <roleContext.Provider value={{ role, setRole }}>
            <MyAdmin />
          </roleContext.Provider>
        </userContext.Provider>
      </StylesProvider>
      <ToastContainer />
    </>
  );
}
