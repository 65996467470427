import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FunctionField,
  required,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  FormDataConsumer,
  DateTimeInput,
  NumberInput,
  AutocompleteInput,
} from "react-admin";
import { validMoney } from "../../Utils/validations.js";
import { days } from "../../Utils/selectValues.js";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const EditPage = (props) => {
  if (role !== "student") {
    return (
      <Edit {...props} transform={(data) => ({ ...data })}>
        <SimpleForm variant="outlined" redirect="list">
          <TextInput
            source="name"
            label="اسم المجموعة"
            validate={required("اسم المجموعة مطلوب")}
          />
          <ReferenceInput
            label="المادة الدراسية"
            source="course_id"
            reference="courses"
            perPage={10}
            sort={{ field: "name", order: "ASC" }}
            validate={required("المادة الدراسية مطلوبة")}
          >
            <AutocompleteInput
              optionText={(choice) => `${choice.name} - ${choice.level}`}
            />
          </ReferenceInput>
          <ReferenceInput
            label="المدرس"
            source="teacher_id"
            reference="teachers"
            perPage={10}
            sort={{ field: "name", order: "ASC" }}
            validate={required("مطلوب")}
          >
            <AutocompleteInput
              optionText={(choice) =>
                `${choice.name} - ${choice.teacher.speciality}`
              }
            />
          </ReferenceInput>
          <RadioButtonGroupInput
            source="payment_collector"
            label="يتم الدفع إلى"
            defaultValue="السنتر"
            choices={[
              { id: "السنتر", name: "السنتر" },
              { id: "المدرس", name: "المدرس" },
            ]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.payment_collector === "السنتر" && (
                <SelectInput
                  {...rest}
                  source="payment_type"
                  label="نوع الدفع"
                  choices={[
                    { id: "حصة", name: "حصة" },
                    { id: "شهر", name: "شهر" },
                    /*{ id: "دفعات", name: "دفعات" },*/
                  ]}
                  validate={required("نوع الدفع مطلوب")}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.payment_collector === "السنتر" &&
              formData.payment_type !== undefined && (
                <TextInput
                  {...rest}
                  source="cost"
                  label={"تكلفة ال" + formData?.payment_type}
                  validate={(validMoney, required("مطلوب"))}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.payment_type === "شهر" && (
                <NumberInput
                  {...rest}
                  source="number_of_lectures"
                  label="عدد الحصص في الشهر"
                  validate={required("عدد الحصص مطلوب")}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.payment_type === "شهر" &&
              formData.number_of_lectures !== undefined &&
              formData.cost !== undefined && (
                <NumberInput
                  {...rest}
                  source="cost_per_lecture"
                  label="تكلفة الحصة"
                  initialValue={formData?.cost / formData?.number_of_lectures}
                  //value={formData.cost / formData.number_of_lectures}
                  disabled
                />
              )
            }
          </FormDataConsumer>
          <TextInput
            source="center_cost_per_lecture"
            label="تكلفة المركز الدراسي فى الحصة"
            validate={(validMoney, required("مطلوب"))}
          />

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.payment_type === "شهر" &&
              formData.number_of_lectures !== undefined &&
              formData.cost !== undefined &&
              formData.center_cost_per_lecture !== undefined && (
                <NumberInput
                  {...rest}
                  source="total_cost_per_lecture"
                  label="التكلفة الكلية المخصومة للحصة"
                  initialValue={
                    formData?.cost_per_lecture +
                    parseFloat(formData?.center_cost_per_lecture)
                  }
                  //value={formData.cost / formData.number_of_lectures}
                  disabled
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.payment_type === "حصة" &&
              formData.cost !== undefined &&
              formData.center_cost_per_lecture !== undefined && (
                <NumberInput
                  {...rest}
                  source="total_cost_per_lecture"
                  label="التكلفة الكلية المخصومة للحصة"
                  initialValue={
                    parseFloat(formData?.cost) +
                    parseFloat(formData?.center_cost_per_lecture)
                  }
                  //value={formData.cost / formData.number_of_lectures}
                  disabled
                />
              )
            }
          </FormDataConsumer>
          <ArrayInput
            source="timetables"
            label="المواعيد الاسبوعية"
            validate={required("اضف ميعاد واحد على الاقل")}
          >
            <SimpleFormIterator>
              <SelectInput
                source="day"
                label="اليوم"
                choices={days}
                validate={required("اليوم مطلوب")}
              />
              <ReferenceInput
                label="القاعة"
                source="room_id"
                reference="rooms"
                perPage={10000}
              >
                <SelectInput
                  optionText={
                    <FunctionField
                      render={(record) =>
                        `${record.branch.name} - ${record.name} - ${record.max_capacity} طالب`
                      }
                    />
                  }
                  validate={required("المادة الدراسية مطلوبة")}
                />
              </ReferenceInput>
              <DateTimeInput
                source="from"
                label="موعد بداية الحصة - وتاريخ اول حصة"
                validate={required("مطلوب")}
              />
              <DateTimeInput
                source="to"
                label="موعد نهاية الحصة - وتاريخ اخر حصة"
                validate={required("مطلوب")}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default EditPage;
