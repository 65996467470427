const permissions = {
  // guest: [],
  owner: [
    "attendances",
    "students",
    "teachers",
    "branches",
    "courses",
    "centers",
    "rooms",
    "course_groups",
    "timetables",
    "enrolments",
    "centerProfile",
    "flow",
    "booklets",
    "booklet_instances",
    "exams",
    "scores",
    "users",
  ],
  admin: [
    "attendances",
    "students",
    "teachers",
    "branches",
    "courses",
    "centers",
    "rooms",
    "course_groups",
    "timetables",
    "enrolments",
    "centerProfile",
    "flow",
    "booklets",
    "booklet_instances",
    "exams",
    "scores",
    "users",
  ],
  user: ["users"],
  student: [
    "users",
    "students",
    "flow",
    "attendances",
    "timetables",
    "course_groups",
    "teachers",
    "exams",
    "scores",
    "courses",
  ],
  teacher: ["users", "students", "courses", "exams", "scores"], // check what teacher can do
  developer: ["courses", "centers"],
};

export default permissions;
