import { Show, TextField, SimpleShowLayout, ReferenceField } from "react-admin";

import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const ShowPage = (props) => {
  if (role !== "student") {
    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="رقم" />
          <TextField source="name" label="الاسم" />
          {role !== "teacher" ? (
            <ReferenceField
              link={false}
              label="المدرس"
              source="teacher_id"
              reference="teachers"
            >
              <TextField source="name" />
            </ReferenceField>
          ) : (
            <></>
          )}
          <ReferenceField
            link={false}
            label="المادة الدراسية"
            source="course_id"
            reference="courses"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="total_marks" label="الدرجة العظمى" />
          <TextField source="type" label="النوع" />
          <TextField source="description" label="الوصف" />
        </SimpleShowLayout>
      </Show>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ShowPage;
