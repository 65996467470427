import React, { useState } from "react";
import { MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import SubMenu from "./MySubMenu";

// Icons
import SettingsIcon from "@material-ui/icons/Settings";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import RestoreIcon from "@material-ui/icons/Restore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import HelpOutline from "@material-ui/icons/HelpOutline";

// =================================================================

const storage = JSON.parse(localStorage?.getItem("user"));
const user_id = storage?.id || null;

// =================================================================

export default function StudentMenu({ dense = false, onMenuClick }) {
  const classes = useStyles();
  //  ---------------------------------------

  const [state, setState] = useState({
    studentMenu: false,
    bookletsMenu: false,
    examsMenu: false,
  });

  //  ---------------------------------------

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  //  ---------------------------------------

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  //  ---------------------------------------

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {/* ------------------------------------------------- */}
      <MenuItemLink
        to={{
          pathname: "/students/" + user_id + "/show",
          state: { _scrollToTop: true },
        }}
        primaryText={"بيانات الطالب"}
        leftIcon={<ContactPhoneIcon />}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/flow",
          state: { _scrollToTop: true },
        }}
        primaryText={"المدفوعات"}
        leftIcon={<AttachMoneyIcon />}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/attendances",
          state: { _scrollToTop: true },
        }}
        primaryText={"الحضور"}
        leftIcon={<RestoreIcon />}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/scores",
          state: { _scrollToTop: true },
        }}
        primaryText={"الامتحانات و التقييم"}
        leftIcon={<HelpOutline />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        name="الاعدادات"
        icon={<SettingsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/users/" + user_id,
            state: { _scrollToTop: true },
          }}
          primaryText={"تغيير كلمة المرور"}
          customResources
          dense={dense}
          leftIcon={<VisibilityOffIcon />}
        />
      </SubMenu>
      {/* ------------------------------------------------- */}
    </div>
  );
}

// =================================================================

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

// =================================================================
