import {
  Edit,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  FunctionField,
  required,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;

// ---------------------------------------------------------------

const EditPage = (props) => (
  <Edit
    {...props}
    title="تغيير مجموعة"
    transform={(data) => ({ ...data, branch_id, editType: "changeGroup" })}
    successMessage="تم تغيير المجموعة بنجاح"
  >
    <SimpleForm variant="outlined">
      <ReferenceField
        label="الطالب"
        source="student_id"
        reference="students"
        link="show"
      >
        <FunctionField
          render={(record) => `${record.id} - ${record.name} طالب`}
        />
      </ReferenceField>

      <ReferenceInput
        label="المدرس"
        source="group.teacher_id"
        reference="teachers"
        perPage={10000}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText="name" validate={required("مطلوب")} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="المجموعة"
            source="group_id"
            reference="course_groups"
            filter={{ teacher_id: formData.teacher_id, active: 1 }}
            {...rest}
            perPage={10}
            sort={{ field: "name", order: "ASC" }}
            validate={required("مطلوب")}
          >
            <AutocompleteInput
              optionText={(choice) =>
                `${choice.name} - ${choice.number_of_students} طالب`
              }
            />
          </ReferenceInput>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

// ---------------------------------------------------------------

export default EditPage;
