import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";

// ======================================================

export default function NotFound() {
  return (
    <Card>
      <Title title="Not Found" />
      <CardContent>
        <h1>Not Found</h1>
      </CardContent>
    </Card>
  );
}
