import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  SimpleShowLayout,
  TopToolbar,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  FilterButton,
  DateField,
  FunctionField,
} from "react-admin";
import Actions from "../../Components/Actions.js";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const ExamsActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف امتحان" />
    <ExportButton label="تصدير" />
    <FilterButton />
  </TopToolbar>
);

// ---------------------------------------------------------------
const courseChoice = (choice) => {
  if (choice?.id) {
    return choice.name + " - " + choice.level;
  } else {
    return "";
  }
};

const OwnerAdminFilters = [
  <ReferenceInput
    label="المدرس"
    source="teacher_id"
    reference="teachers"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="المادة"
    source="course_id"
    reference="courses"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={courseChoice} />
  </ReferenceInput>,
  <SelectInput
    label="نوع الامتحان"
    source="type"
    choices={[
      { id: "شامل", name: "شامل" },
      { id: "تقييم", name: "تقييم" },
    ]}
  />,
];

const TeacherFilters = [
  <SelectInput
    label="نوع الامتحان"
    source="type"
    choices={[
      { id: "شامل", name: "شامل" },
      { id: "تقييم", name: "تقييم" },
    ]}
  />,
];

// ---------------------------------------------------------------

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <TextField source="id" label="رقم" />
      <TextField source="name" label="الاسم" />
      {role !== "teacher" ? (
        <ReferenceField label="المدرس" source="teacher_id" reference="teachers">
          <TextField source="name" />
        </ReferenceField>
      ) : (
        <></>
      )}
      <ReferenceField
        label="المادة الدراسية"
        source="course_id"
        reference="courses"
        link={false}
      >
        <FunctionField
          render={(record) => `${record.name} - ${record.level}`}
        />
      </ReferenceField>
      <TextField source="total_marks" label="الدرجة العظمى" />
      <TextField source="type" label="النوع" />
      <TextField source="description" label="الوصف" />
    </SimpleShowLayout>
  );
};
// ---------------------------------------------------------------

const ListPage = (props) => {
  if (role !== "student") {
    return (
      <List
        {...props}
        actions={<ExamsActions />}
        filter={role === "teacher" ? { teacher_id: user_id } : false}
        filters={role === "teacher" ? TeacherFilters : OwnerAdminFilters}
      >
        <Datagrid>
          <TextField source="id" label="رقم" />
          <DateField source="created_at" label="تاريخ إضافة الامتحان" />
          <TextField source="name" label="الاسم" />
          {role !== "teacher" ? (
            <ReferenceField
              label="المدرس"
              source="teacher_id"
              reference="teachers"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          ) : (
            <></>
          )}
          <ReferenceField
            label="المادة الدراسية"
            source="course_id"
            reference="courses"
            link={false}
          >
            <FunctionField
              render={(record) => `${record.name} - ${record.level}`}
            />
          </ReferenceField>
          <TextField source="total_marks" label="الدرجة العظمى" />
          <TextField source="type" label="النوع" />
          <TextField source="description" label="الوصف" />
          <Actions label="الاعدادات">
            <ShowButton label="عرض" />
            <EditButton label="تعديل" />
            <DeleteWithCustomConfirmButton
              title="تأكيد حذف امتحان!"
              content={DeleteConfirmContent} // your custom contents of delete confirm dialog
              label="حذف" // label of delete button (default: 'Delete')
              ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
              cancel="غلق" // label of cancel button (default: 'Cancel')
            />
          </Actions>
        </Datagrid>
      </List>
    );
  } else {
    return <NotFound />;
  }
};
// ---------------------------------------------------------------

export default ListPage;
