import React, { useContext } from "react";
import {
  List,
  TextField,
  CreateButton,
  ShowButton,
  EditButton,
  TopToolbar,
  BulkDeleteButton,
  NumberField,
  useRedirect,
  TextInput,
  SelectInput,
  FilterButton,
  ExportButton,
  EmailField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import CustomizableDatagrid from "ra-customizable-datagrid";
import Actions from "../../Components/Actions.js";
import { ReactReduxContext } from "react-redux";
import printJS from "print-js";
import { levels } from "../../Utils/selectValues.js";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const StudentsActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف طالب" />
    {role === "owner" ? (
      <ExportButton label="تصدير" maxResults={1000000} />
    ) : (
      <></>
    )}
    <FilterButton label="" />
  </TopToolbar>
);

// ---------------------------------------------------------------

const BulkActionButtons = (props) => {
  const { store } = useContext(ReactReduxContext);
  const studentsRows = store.getState()?.admin?.resources?.students?.data;
  const { selectedIds } = props;

  // ****************************************

  const printRows = () => {
    const selectedRows = selectedIds.map((id, index) => ({
      index: index + 1,
      ...studentsRows[id],
      ...studentsRows[id].student,
    }));
    printJS({
      printable: selectedRows,
      properties: [
        { field: "vodafone_cash", displayName: "فودافون كاش" },
        { field: "whats_app", displayName: "واتس اب" },
        { field: "parent_phone", displayName: "هاتف ولى الامر" },
        { field: "phone", displayName: "هاتف" },
        { field: "level", displayName: "الصف" },
        { field: "name", displayName: "الاسم" },
        { field: "id", displayName: "كود" },
      ],
      type: "json",
      style: `
      table { border-collapse: collapse; table-layout: auto;}
      tr:nth-child(odd) { background-color: #f2f2f2;}
      th,td { border: 1px solid grey;}
      `,
      gridStyle: "text-align:center;",
    });
  };

  // ****************************************

  return (
    <>
      <Button onClick={printRows} label="Show calendar">
        Print
      </Button>
      <BulkDeleteButton {...props} />
    </>
  );
};

// ---------------------------------------------------------------

const filters = [
  <TextInput label="بحث" source="q" alwaysOn />,
  <SelectInput
    label="المستوى الدراسي"
    source="student.level"
    choices={levels}
  />,
];

// ---------------------------------------------------------------

const ListPage = (props) => {
  const redirect = useRedirect();
  if (role === "admin" || role === "owner") {
    return (
      <List
        {...props}
        actions={<StudentsActions />}
        filters={filters}
        bulkActionButtons={<BulkActionButtons />}
      >
        <CustomizableDatagrid>
          <TextField source="id" label="كود الطالب" />
          <TextField source="name" label="الاسم" />
          <EmailField source="email" label="البريد الإلكتروني" />
          <TextField source="student.level" label="الصف الدراسي" />
          <NumberField
            source="student.balance"
            label="رصيد الطالب"
            options={{ style: "currency", currency: "EGP" }}
          />
          <TextField source="student.phone" label="الهاتف" />
          <TextField source="student.parent_phone" label="هاتف ولى الامر" />
          <TextField source="student.whats_app" label="واتس اب" />
          <TextField source="student.vodafone_cash" label="فودافون كاش" />
          <Actions label="الاعدادات">
            <ShowButton label="عرض" />
            <EditButton label="تعديل" />
          </Actions>
        </CustomizableDatagrid>
      </List>
    );
  } else if (role === "student") {
    redirect("show", "/students", storage.id);
    return <></>;
  } else if (role === "teacher") {
    redirect("list", "/exams");
    return <></>;
  }
};

// ---------------------------------------------------------------

export default ListPage;
