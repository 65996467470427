import {
  List,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  DeleteButton,
  TopToolbar,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import Actions from "../../Components/Actions.js";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const center_id = storage?.center_id || null;

// ---------------------------------------------------------------

const BranchesActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف فرع" />
    <ExportButton label="تصدير" />
  </TopToolbar>
);

// ---------------------------------------------------------------

const ListPage = (props) => (
  <List {...props} actions={<BranchesActions />} filter={{ center_id }}>
    <CustomizableDatagrid>
      <TextField source="id" label="رقم" />
      <TextField source="name" label="الاسم" />
      <TextField source="number_of_rooms" label="عدد الغرف" />
      <ReferenceManyField label="الغرف" reference="rooms" target="branch_id">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="address" label="العنوان" />
      <Actions label="الاعدادات">
        <ShowButton label="عرض" />
        <EditButton label="تعديل" />
        <DeleteButton label="حذف" />
      </Actions>
    </CustomizableDatagrid>
  </List>
);

// ---------------------------------------------------------------

export default ListPage;
