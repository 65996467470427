import ListPage from "./branches.List";
import ShowPage from "./branches.Show";
import CreatePage from "./branches.Create";
import EditPage from "./branches.Show";

// ---------------------------------------------------------------

const branches = {
  name: "branches",
  label: "الفروع",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default branches;
