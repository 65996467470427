import ListPage from "./students.List";
import ShowPage from "./students.Show";
import CreatePage from "./students.Create";
import EditPage from "./students.Edit";

// ---------------------------------------------------------------

const students = {
  name: "students",
  label: "الطلاب",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default students;
