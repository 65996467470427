import {
  List,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  SimpleShowLayout,
  TopToolbar,
  TextInput,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import Actions from "../../Components/Actions.js";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const filters = [<TextInput label="بحث" source="q" alwaysOn />];

// ---------------------------------------------------------------

const TeachersAction = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف مدرس" />
    <ExportButton label="تصدير" />
  </TopToolbar>
);

// ---------------------------------------------------------------

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <TextField source="name" label="الاسم" />
      <TextField source="teacher.phone" label="الهاتف" />
      <TextField source="teacher.speciality" label="التخصص" />
    </SimpleShowLayout>
  );
};

// ---------------------------------------------------------------

const ListPage = (props) => {
  if (role !== "student") {
    return (
      <List {...props} actions={<TeachersAction />} filters={filters}>
        <CustomizableDatagrid>
          <TextField source="id" label="كود المدرس" />
          <TextField source="name" label="الاسم" />
          <TextField source="teacher.phone" label="الهاتف" />
          <TextField source="teacher.speciality" label="التخصص" />
          <Actions label="الاعدادات">
            <ShowButton label="عرض" />
            <EditButton label="تعديل" />
            <DeleteWithCustomConfirmButton
              title="تأكيد حذف بيانات المدرس!"
              content={DeleteConfirmContent} // your custom contents of delete confirm dialog
              label="حذف" // label of delete button (default: 'Delete')
              ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
              cancel="غلق" // label of cancel button (default: 'Cancel')
            />
          </Actions>
        </CustomizableDatagrid>
      </List>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ListPage;
