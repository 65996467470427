// in ./CustomResetViewsButton.js
import * as React from "react";
import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const CustomResetViewsButton = ({ selectedIds }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "attendances",
    selectedIds,
    { settelled_accounting: true, branch_id, user_id },
    {
      onSuccess: () => {
        refresh();
        notify("تم التسوية بنجاح");
        unselectAll("attendances");
      },
      onFailure: (error) =>
        notify("حدث خطأ اثناء التسوية", { type: "warning" }),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        label="تسوية حساب المدرس"
        onClick={handleClick}
        startIcon={<CheckIcon />}
        alignIcon="left"
        size="large"
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title="تأكيد التسوية"
        content="تأكد من طباعة التقرير قبل التسوية - سيتم تسوية الحصص التي تم اختيارها"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        cancel="اغلاق"
        confirm="تأكيد"
      />
    </Fragment>
  );
};

export default CustomResetViewsButton;
