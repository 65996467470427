import {
  Edit,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  required,
  FunctionField,
  DateTimeInput,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const EditPage = (props) => {
  if (role !== "student") {
    return (
      <Edit {...props} transform={(data) => ({ ...data })}>
        <SimpleForm variant="outlined">
          <SelectInput
            source="day"
            label="اليوم"
            validate={required("اليوم مطلوب")}
            choices={[
              { id: "السبت", name: "السبت" },
              { id: "الاحد", name: "الاحد" },
              { id: "الاثنين", name: "الاثنين" },
              { id: "الثلاثاء", name: "الثلاثاء" },
              { id: "الاربعاء", name: "الاربعاء" },
              { id: "الخميس", name: "الخميس" },
              { id: "الجمعة", name: "الجمعة" },
            ]}
          />
          <ReferenceInput
            label="القاعة"
            source="room_id"
            reference="rooms"
            perPage={1000}
          >
            <SelectInput
              validate={required("المادة الدراسية مطلوبة")}
              optionText={
                <FunctionField
                  render={(record) =>
                    `قاعة ${record.name} - ${record.max_capacity} طالب`
                  }
                />
              }
            />
          </ReferenceInput>
          <DateTimeInput
            source="from"
            label="من"
            validate={required("مطلوب")}
          />
          <DateTimeInput source="to" label="إلى" validate={required("مطلوب")} />
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default EditPage;
