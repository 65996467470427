import React, { useEffect } from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  NumberField,
  EmailField,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";
import JsBarcode from "jsbarcode";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const Barcode = ({ id }) => {
  useEffect(() => JsBarcode("#barcode", id, { height: 50, width: 3 }), [id]);
  return (
    <div style={{ margin: "20px 0" }}>
      <img alt="student-barcode" id="barcode" />
    </div>
  );
};

// ---------------------------------------------------------------

const ShowPage = (props) => {
  console.log(storage.id);
  if ((role === "student" && props.id == storage.id) || role !== "student") {
    return (
      <Show {...props} hasEdit={role !== "student"}>
        <SimpleShowLayout>
          <Barcode id={props.id} />
          <TextField source="name" label="الاسم" />
          <EmailField source="email" label="البريد الإلكتروني" />
          <NumberField
            source="student.balance"
            label="رصيد الطالب"
            options={{ style: "currency", currency: "EGP" }}
          />
          <TextField source="student.level" label="الصف الدراسي" />
          <TextField source="student.phone" label="الهاتف" />
          <TextField source="student.parent_phone" label="هاتف ولى الامر" />
          <TextField source="student.whats_app" label="واتس اب" />
          <TextField source="student.vodafone_cash" label="فودافون كاش" />
        </SimpleShowLayout>
      </Show>
    );
  } else {
    return <NotFound />;
  }
};
// ---------------------------------------------------------------

export default ShowPage;
