import ListPage from "./courses.List";
import CreatePage from "./courses.Create";
import ShowPage from "./courses.Show";
import EditPage from "./courses.Edit";

// ---------------------------------------------------------------

const courses = {
  name: "courses",
  label: "المواد الدراسية",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default courses;
