import { Show, TextField, SimpleShowLayout } from "react-admin";

// ---------------------------------------------------------------

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="رقم" />
      <TextField source="name" label="اسم الفرع" />
      <TextField source="number_of_rooms" label="عدد الغرف" />
      <TextField source="address" label="العنوان" />
    </SimpleShowLayout>
  </Show>
);

// ---------------------------------------------------------------

export default ShowPage;
