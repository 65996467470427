import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  EditButton,
  TopToolbar,
  ReferenceField,
  NumberField,
  FilterButton,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  Pagination,
  DateInput,
  FunctionField,
} from "react-admin";
import Actions from "../../Components/Actions.js";

// ---------------------------------------------------------------

const BookletInstancesActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="حجز مذكرة" />
    <ExportButton label="تصدير" />
    <FilterButton />
  </TopToolbar>
);

// ---------------------------------------------------------------

const filters = [
  <DateInput  label="من تاريخ" source="from_date" />,
  <DateInput  label="الى تاريخ" source="to_date" />,

  <ReferenceInput
    label="المذكرة"
    source="booklet_id"
    reference="booklets"
    perPage={10000}
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="الطالب"
    source="student_id"
    reference="students"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <SelectInput
    label="الحالة"
    source="status"
    choices={[
      { id: "حجز", name: "حجز" },
      { id: "استلام", name: "استلام" },
    ]}
  />,
];

// ---------------------------------------------------------------

const ListPage = (props) => (
  <List
    {...props}
    actions={<BookletInstancesActions />}
    filters={filters}
    pagination={
      <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
    }
  >
    <Datagrid>
      <TextField source="id" label="رقم" />
      <FunctionField
        source="created_at"
        label="التاريخ"
        render={(record) =>
          `${new Date(record.created_at).toLocaleDateString()}`
        }
      />
      <ReferenceField
        label="الطالب"
        source="student_id"
        reference="students"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="المذكرة"
        source="booklet_id"
        reference="booklets"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="السعر"
        source="booklet_id"
        reference="booklets"
        link={false}
      >
        <NumberField
          source="cost"
          options={{ style: "currency", currency: "EGP" }}
        />
      </ReferenceField>
      <NumberField
        source="paid"
        label="المدفوع"
        options={{ style: "currency", currency: "EGP" }}
      />
      <TextField source="status" label="الحالة" />
      <Actions label="الاعدادات">
        <EditButton label="المدفوعات و التسليم" />
      </Actions>
    </Datagrid>
  </List>
);

// ---------------------------------------------------------------

export default ListPage;
