import CreatePage from "./flow.Create";
import ListPage from "./flow.List";
import ShowPage from "./flow.Show";

// ---------------------------------------------------------------

const flow = {
  name: "flow",
  label: "الحسابات",
  create: CreatePage,
  list: ListPage,
  show: ShowPage,
};

// ---------------------------------------------------------------

export default flow;
