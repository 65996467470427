import{
    regex,
    minValue,
    maxValue,
    number,
} from "react-admin";

// =========================================================================

export const validPhone = [regex(/^01[0125][0-9]{8}$/, 'رقم الموبايل غير صحيح')];

export const validMoney = [
    number('يجب ان يكون رقم'),
    minValue(1,'يجب ان يكون الرقم اكبر من صفر'), 
    maxValue(999999,'اقصى رقم مقبول 999,999.00'), 
    regex(/^[0-9]+(\.[0-9]{1,2})?$/,'مبلغ غير صحيح')
];
  