import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceField,
  FunctionField,
  BooleanInput,
} from "react-admin";
import { validMoney } from "../../Utils/validations.js";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const WithProps = ({ children, ...props }) => children(props);

// ---------------------------------------------------------------

const EditPage = (props) => (
  <Edit
    {...props}
    transform={(data) => ({ ...data, branch_id, user_id })}
    mutationMode="pessimistic"
  >
    <WithProps>
      {({ record, ...props }) => (
        <SimpleForm {...props} variant="outlined">
          <ReferenceField
            label="المذكرة"
            source="booklet_id"
            reference="booklets"
            link="show"
          >
            <FunctionField
              render={(record) => `${record.name} - ${record.cost} ج.م.`}
            />
          </ReferenceField>
          <FunctionField
            source="paid"
            label="المدفوع"
            render={(record) => `${record.paid} ج.م.`}
          />
          <ReferenceField
            label="باقي المبلغ المطلوب"
            source="booklet_id"
            reference="booklets"
            link={false}
          >
            <FunctionField
              render={(booklet) => `${booklet.cost - record.paid} ج.م.`}
            />
          </ReferenceField>
          <TextInput source="payment" label="المبلغ المدفوع" validate={validMoney}/>
          <BooleanInput label="تسليم" source="status" />
        </SimpleForm>
      )}
    </WithProps>
  </Edit>
);

// ---------------------------------------------------------------

export default EditPage;
