import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import Card from "@material-ui/core/Card";
import useMyQuery from "../API/api.hooks";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useGetList } from "react-admin";
import arLocale from "@fullcalendar/core/locales/ar";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import NotFound from "../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const days = {
  الاثنين: 1,
  الثلاثاء: 2,
  الاربعاء: 3,
  الخميس: 4,
  الجمعة: 5,
  السبت: 6,
  الاحد: 0,
};

// =========================================================

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

// =========================================================

export default function TimeTable() {
  const [selectedBranch, setSelectedBranch] = useState(
    JSON.parse(localStorage?.user)?.branch_id || ""
  );
  const [selectedRoom, setSelectedRoom] = useState("all");

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [info] = useState();

  // --------------------------------------

  const handleClose = () => setOpen(false);

  // --------------------------------------

  const { data: timetables } = useMyQuery({
    queryName: "getTimetable",
  });

  const { data: branches, ids: branchesIds } = useGetList(
    "branches",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { center_id: 1 }
  );

  const { data: rooms, ids: roomsIds } = useGetList(
    "rooms",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { branch_id: selectedBranch }
  );

  const modaStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-70%, -50%)",
  };

  // --------------------------------------

  const body = (
    <div style={modaStyle} className={classes.paper}>
      {JSON.stringify(info, null, 2)}
    </div>
  );

  // --------------------------------------

  let events = timetables?.rows || [];

  if (selectedBranch) {
    events = events.filter((e) => e?.room?.branch_id === selectedBranch);
  }

  if (selectedRoom && selectedRoom !== "all") {
    events = events.filter((e) => e?.room_id === selectedRoom);
  }

  events =
    events?.map((event, index) => {
      let { day, group, room, from, to } = event;
      return {
        title: `${group?.teacher?.id_user?.name} - ${group?.name} - قاعة: ${room?.name}`,
        startRecur: from.slice(0, 10),
        endRecur: to.slice(0, 10),
        startTime: new Date(from).toLocaleTimeString("en-GB"),
        endTime: new Date(to).toLocaleTimeString("en-GB"),
        daysOfWeek: [days[day]],
      };
    }) || [];

  // --------------------------------------

  if (role === "owner" || role === "admin") {
    return (
      <Card style={{ textAlign: "center" }}>
        <div style={{ marginTop: "10px", marginBottom: "30px" }}>
          <FormControl
            style={{
              margin: "10px",
              textAlign: "center",
              width: "200px",
            }}
          >
            <InputLabel id="demo-simple-select-label">الفرع</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
            >
              {branchesIds?.map((branchId, index) => (
                <MenuItem value={branchId} key={index}>
                  {branches[branchId]?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              margin: "10px",
              textAlign: "center",
              width: "200px",
            }}
          >
            <InputLabel id="demo-simple-select-label">قاعة</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(e.target.value)}
            >
              <MenuItem value="all">كل القاعات</MenuItem>
              {roomsIds?.map((roomId, index) => (
                <MenuItem value={roomId} key={index}>
                  {rooms[roomId]?.branch.name + " - " + rooms[roomId]?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <FullCalendar
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          locale={arLocale}
          initialView="listWeek"
          editable={false}
          selectMirror={true}
          dayMaxEvents={true}
          slotMinTime="6:00:00"
          events={events}
          selectable={true}
          /*select={(time) => {
          setInfo(time);
          handleOpen();
        }}*/
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </Card>
    );
  } else {
    return <NotFound />;
  }
}
