import React, { createElement, useRef, useState } from "react";
import classnames from "classnames";
import { Card, TextField, Button, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import JsBarcode from "jsbarcode";
import * as htmlToImage from "html-to-image";

// ====================================================================

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
    },
    card: {
      minWidth: 450,
      marginTop: "1em",
      display: "flex",
      flexDirection: "column",
      padding: "3em",
      alignItems: "center",
      verticalAlign: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: 370,
    },
    input: {
      width: 370,
      margin: "10px 0px",
    },
    button: {
      height: "50px",
      margin: "35px 0px 0px 0px",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {},
    student_card: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      verticalAlign: "center",
    },
  }),
  { name: "RaLogin" }
);

// ====================================================================

export default function PrintID(props) {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    staticContext,
    backgroundImage,
    ...rest
  } = props;

  // ----------------------------------------------------

  const containerRef = useRef();
  const classes = useStyles(props);

  // ----------------------------------------------------

  const [values, setValues] = useState({
    from_id: 20220001,
    to_id: 20220001,
    height: 130,
    width: 3,
    margin: 50,
  });

  // ----------------------------------------------------

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  // ----------------------------------------------------

  const Barcode = async () => {
    for (let idValue = values.from_id; idValue <= values.to_id; idValue++) {
      await JsBarcode("#barcode", idValue, {
        height: values.height,
        width: values.width,
        margin: values.margin,
        fontSize: 30,
      });
      await htmlToImage
        .toJpeg(document.getElementById("barcode"))
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = idValue + ".jpeg";
          link.href = dataUrl;
          link.click();
          link.remove();
        });
    }
  };

  // ----------------------------------------------------

  return (
    <div
      className={classnames(classes.main, className)}
      {...rest}
      ref={containerRef}
    >
      <Card className={classes.card}>
        <h2>طباعة الكارنيهات</h2>
        {/* ======================================================= */}
        <form className={classes.form} onSubmit={Barcode}>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            من رقم
            <TextField
              type="number"
              name="from_id"
              onChange={handleChange}
              value={values.from_id}
              className={classes.input}
              required
            />
          </InputLabel>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            إلى رقم
            <TextField
              type="number"
              name="to_id"
              onChange={handleChange}
              defaultValue={values.to_id}
              className={classes.input}
              required
            />
          </InputLabel>
          <InputLabel className={classes.input}>
            عرض
            <TextField
              type="number"
              name="width"
              onChange={handleChange}
              value={values.width}
              className={classes.input}
              required
            />
          </InputLabel>
          <InputLabel className={classes.input}>
            ارتفاع
            <TextField
              type="number"
              name="height"
              onChange={handleChange}
              defaultValue={values.height}
              className={classes.input}
              required
            />
          </InputLabel>
          <InputLabel className={classes.input}>
            فراغ
            <TextField
              type="number"
              name="margin"
              onChange={handleChange}
              defaultValue={values.margin}
              className={classes.input}
              required
            />
          </InputLabel>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
          >
            طباعة
          </Button>
        </form>
        <div id="printed_card" className={classes.student_card}>
          <img id="barcode" alt="barcode" />
        </div>
      </Card>
      {notification ? createElement(notification) : null}
    </div>
  );
}
