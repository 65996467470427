import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  DeleteButton,
  TopToolbar,
  ReferenceField,
} from "react-admin";
import Actions from "../../Components/Actions.js";

// ---------------------------------------------------------------

const RoomsActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف قاعة" />
    <ExportButton label="تصدير" />
  </TopToolbar>
);

// ---------------------------------------------------------------

const ListPage = (props) => (
  <List {...props} actions={<RoomsActions />}>
    <Datagrid>
      <ReferenceField
        label="الفرع"
        source="branch_id"
        reference="branches"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="اسم القاعة" />
      <TextField source="max_capacity" label="اقصى سعة" />
      <Actions label="الاعدادات">
        <ShowButton label="عرض" />
        <EditButton label="تعديل" />
        <DeleteButton label="حذف" />
      </Actions>
    </Datagrid>
  </List>
);

// ---------------------------------------------------------------

export default ListPage;
