import {
  List,
  Datagrid,
  TextField,
  FilterButton,
  TopToolbar,
  ReferenceField,
  FunctionField,
  BooleanField,
  useListContext,
  DateInput,
  SelectInput,
  ReferenceInput,
  NumberField,
  AutocompleteInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomResetViewsButton from "./attendance.Edit.js";
import { Fragment } from "react";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const AttendanceBulkActionButtons = (props) => (
  <Fragment>
    <CustomResetViewsButton label="Reset Views" {...props} />
  </Fragment>
);

// ---------------------------------------------------------------

const Empty = () => {
  return (
    <Box textAlign="center" m={8}>
      <Typography variant="h5" paragraph>
        لا يوجد أي تسجيلات حضور بالاعدادات المحددة
      </Typography>
    </Box>
  );
};

// ---------------------------------------------------------------

function total_lecture_cost(data) {
  let settelled = 0;
  let notSettelled = 0;
  for (let i in data) {
    if (data[i].settelled_accounting === true) {
      settelled += data[i].lecture_cost;
    } else if (data[i].settelled_accounting === false) {
      notSettelled += data[i].lecture_cost;
    }
  }
  return { settelled, notSettelled };
}

// ---------------------------------------------------------------

const Aside = () => {
  const { total, data } = useListContext();
  const { settelled, notSettelled } = total_lecture_cost(data);
  return (
    <div style={{ width: 250, margin: "1em" }}>
      <Typography variant="h5">تسوية حساب المدرس</Typography>
      <Typography variant="body1">عدد الطلبة : {total}</Typography>
      <Typography variant="body1">
        إجمالي تكلفة الحصة :{" "}
        {(settelled + notSettelled)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,") + " "}
        ج.م.
      </Typography>
      <Typography variant="body1">
        تم تسوية مبلغ :{" "}
        {settelled.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " "}
        ج.م.
      </Typography>
      <Typography variant="body1">
        لم يتم تسوية مبلغ :{" "}
        {notSettelled.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " "}
        ج.م.
      </Typography>
      {/* <Button label="تسوية حساب المدرس" basePath="/edit"></Button> */}
    </div>
  );
};

// ---------------------------------------------------------------

const studentChoice = (choice) => {
  if (choice?.id) {
    return choice.id + " - " + choice.name;
  } else {
    return "";
  }
};

const teacherChoice = (choice) => {
  if (choice?.id) {
    return choice.name + " - " + choice.teacher.speciality;
  } else {
    return "";
  }
};

const AttendanceFilters = [
  <DateInput alwaysOn label="من تاريخ" source="from_date" />,
  <DateInput alwaysOn label="الى تاريخ" source="to_date" />,
  <ReferenceInput
    label="الطالب"
    source="student_id"
    reference="students"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={studentChoice} />
  </ReferenceInput>,
  <ReferenceInput
    label="المدرس"
    source="teacher_id"
    reference="teachers"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={teacherChoice} />
  </ReferenceInput>,

  <ReferenceInput
    label="المجموعة"
    source="group_id"
    reference="course_groups"
    pagination={false}
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
    filter={{ active: 1 }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,

  <SelectInput
    label="التسوية"
    source="settelled_accounting"
    choices={[
      { id: true, name: "تم التسوية" },
      { id: false, name: "لم يتم التسوية" },
    ]}
  />,
];

// ---------------------------------------------------------------

const AttendancesActions = ({ basePath, data }) => (
  <TopToolbar>
    {/*<CreateButton basePath={basePath} label="تسجيل الحضور" />
    <ExportButton label="تصدير" />*/}
    {(role === "owner" || role === "admin") && (
      <FilterButton label="اضف فلتر" />
    )}
  </TopToolbar>
);

// ---------------------------------------------------------------

const ListPage = (props) => (
  <List
    {...props}
    actions={<AttendancesActions />}
    bulkActionButtons={
      role === "owner" || role === "admin" ? (
        <AttendanceBulkActionButtons />
      ) : (
        false
      )
    }
    aside={role === "owner" || role === "admin" ? <Aside /> : false}
    pagination={false}
    perPage={1000}
    filters={AttendanceFilters}
    filter={role === "student" ? { student_id: storage?.id } : false}
    filterDefaultValues={
      role === "student"
        ? {
            from_date: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ),
            to_date: new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
            ),
          }
        : {
            from_date: new Date().toDateString(),
            to_date: new Date().toDateString(),
          }
    }
  >
    <Datagrid empty={<Empty />}>
      <TextField source="id" label="رقم" />
      <FunctionField
        source="created_at"
        label="التاريخ"
        render={(record) =>
          `${new Date(record.created_at).toLocaleDateString()}`
        }
      />
      <ReferenceField
        label="المدرس"
        source="teacher_id"
        reference="teachers"
        link={role === "student" ? false : "show"}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="المجموعة"
        source="group_id"
        reference="course_groups"
        link={role === "student" ? false : "show"}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="lecture_cost"
        label="تكلفة الحصة"
        options={{ style: "currency", currency: "EGP" }}
      />
      <NumberField
        source="center_cost_per_lecture"
        label="تكلفة السنتر"
        options={{ style: "currency", currency: "EGP" }}
      />
      <ReferenceField
        label="الميعاد"
        source="timetable_id"
        reference="timetables"
        link={role === "student" ? false : "show"}
      >
        <FunctionField
          render={(record) =>
            `${record.day}  - ${new Date(record.from).toLocaleTimeString()}`
          }
        />
      </ReferenceField>
      <FunctionField
        source="created_at"
        label="ميعاد الوصول"
        render={(record) =>
          `${new Date(record.created_at).toLocaleTimeString()}`
        }
      />

      <BooleanField source="home_work" label="الواجب" />
      <TextField source="home_work_notes" label="ملاحظات الواجب" />

      {role !== "student" && (
        <ReferenceField
          label="الطالب"
          source="student_id"
          reference="students"
          pagination={false}
        >
          <FunctionField
            render={(record) => `${record.id}  - ${record.name}`}
          />
        </ReferenceField>
      )}
      {role !== "student" && (
        <BooleanField
          source="settelled_accounting"
          label="تم التسوية"
          //TrueIcon={AlarmOnIcon}
          //FalseIcon={AlarmOffIcon}
        />
      )}
    </Datagrid>
  </List>
);

// ---------------------------------------------------------------

export default ListPage;
