import ListPage from "./booklets.List";
import ShowPage from "./booklets.Show";
import CreatePage from "./booklets.Create";
import EditPage from "./booklets.Show";

// ---------------------------------------------------------------

const booklets = {
  name: "booklets",
  label: "المذكرات",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default booklets;
