import { Show, TextField, SimpleShowLayout, ReferenceField } from "react-admin";

// ---------------------------------------------------------------

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="رقم" />
      <ReferenceField label="الطالب" source="student_id" reference="students">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="المذكرة" source="booklet_id" reference="booklets">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="السعر" source="booklet_id" reference="booklets">
        <TextField source="cost" />
      </ReferenceField>
      <TextField source="paid" label="المدفوع" />
    </SimpleShowLayout>
  </Show>
);

// ---------------------------------------------------------------

export default ShowPage;
