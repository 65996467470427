import { useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  useMutation,
  useRedirect,
} from "react-admin";
import { validMoney } from "../../Utils/validations.js";
import { toast } from "react-toastify";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  const [mutate, { data }] = useMutation();
  const redirect = useRedirect();

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  const submitHanlder = async (values) => {
    const payload = { data: { ...values, branch_id, user_id } };
    const mutatePayload = {
      type: "create",
      resource: "booklet_instances",
      payload,
    };
    try {
      await mutate(mutatePayload, { returnPromise: true });
    } catch (error) {
      console.error({ error });
      return error;
    }
  };

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  useEffect(() => {
    if (data) {
      toast.success(`تم حجز المذكرة بنجاح`, { theme: "dark" });
      if (data?.id) {
        redirect(`/flow/${data?.id}/show`, data);
      }
    }
  }, [data, redirect]);

  // ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

  return (
    <Create {...props} title="اضافة مذكرة">
      <SimpleForm variant="outlined" save={submitHanlder}>
        <ReferenceInput
          label="الطالب"
          source="student_id"
          reference="students"
          perPage={10}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionText={(choice) => `${choice.id} - ${choice.name}`}
            validate={required("مطلوب")}
          />
        </ReferenceInput>

        <ReferenceInput
          label="المذكرة"
          source="booklet_id"
          reference="booklets"
          perPage={10}
          sort={{ field: "name", order: "ASC" }}
          validate={required("مطلوب")}
          filter={{ active: 1 }}
        >
          <AutocompleteInput
            optionText={(choice) => `${choice.name} - ${choice.cost} ج.م.`}
          />
        </ReferenceInput>
        <TextInput source="paid" label="مبلغ الحجز" validate={validMoney} />
      </SimpleForm>
    </Create>
  );
};

// ---------------------------------------------------------------

export default CreatePage;
