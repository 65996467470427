import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  SimpleShowLayout,
  TopToolbar,
  TextInput,
} from "react-admin";
import Actions from "../../Components/Actions.js";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const filters = [<TextInput label="بحث" source="q" alwaysOn />];

// ---------------------------------------------------------------

const CoursesActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف مادة" />
    <ExportButton label="تصدير" />
  </TopToolbar>
);
// ---------------------------------------------------------------

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <TextField source="id" label="رقم" />
      <TextField source="name" label="الاسم" />
      <TextField source="level" label="الصف الدراسي" />
    </SimpleShowLayout>
  );
};
// ---------------------------------------------------------------

const ListPage = (props) => {
  if (role !== "student") {
    return (
      <List {...props} actions={<CoursesActions />} filters={filters}>
        <Datagrid>
          <TextField source="id" label="رقم" />
          <TextField source="name" label="الاسم" />
          <TextField source="level" label="الصف الدراسي" />
          <Actions label="الاعدادات">
            <ShowButton label="عرض" />
            <EditButton label="تعديل" />
            <DeleteWithCustomConfirmButton
              title="تأكيد حذف مادة!"
              content={DeleteConfirmContent} // your custom contents of delete confirm dialog
              label="حذف" // label of delete button (default: 'Delete')
              ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
              cancel="غلق" // label of cancel button (default: 'Cancel')
            />
          </Actions>
        </Datagrid>
      </List>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ListPage;
