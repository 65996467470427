import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  SimpleShowLayout,
  TopToolbar,
  ReferenceField,
  FunctionField,
  FilterButton,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import Actions from "../../Components/Actions.js";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// =================================================================

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const Empty = () => {
  return (
    <Box textAlign="center" m={8}>
      <Typography variant="h5" paragraph>
        لا يوجد امتحانات
      </Typography>
    </Box>
  );
};

// ---------------------------------------------------------------

const ScoresActions = ({ basePath, data }) => (
  <TopToolbar>
    {role !== "student" ? (
      <CreateButton basePath={basePath} label="اضف درجة طالب" />
    ) : (
      <></>
    )}
    {role !== "student" ? <ExportButton label="تصدير" /> : <></>}
    <FilterButton />
  </TopToolbar>
);

// ---------------------------------------------------------------

const studentChoice = (choice) => {
  if (choice?.id) {
    return choice.id + " - " + choice.name;
  } else {
    return "";
  }
};

// ---------------------------------------------------------------

const OwnerAdminFilters = [
  <ReferenceInput
    label="المدرس"
    source="teacher_id"
    reference="teachers"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="الطالب"
    source="student_id"
    reference="students"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={studentChoice} />
  </ReferenceInput>,

  <ReferenceInput
    label="الامتحان"
    source="exam_id"
    reference="exams"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
    filter={role === "teacher" ? { teacher_id: user_id } : null}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,

  <SelectInput
    label="النوع"
    source="type"
    choices={[
      { id: "شامل", name: "شامل" },
      { id: "تقييم", name: "تقييم" },
    ]}
  />,
];

// ---------------------------------------------------------------

const TeacherFilters = [
  <ReferenceInput
    label="الطالب"
    source="student_id"
    reference="students"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={studentChoice} />
  </ReferenceInput>,

  <ReferenceInput
    label="الامتحان"
    source="exam_id"
    reference="exams"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
    filter={role === "teacher" ? { teacher_id: user_id } : null}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,

  <SelectInput
    label="النوع"
    source="type"
    choices={[
      { id: "شامل", name: "شامل" },
      { id: "تقييم", name: "تقييم" },
    ]}
  />,
];

// ---------------------------------------------------------------

const StudentFilters = [
  <ReferenceInput
    label="المدرس"
    source="teacher_id"
    reference="teachers"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,

  <ReferenceInput
    label="الامتحان"
    source="exam_id"
    reference="exams"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,

  <SelectInput
    label="النوع"
    source="type"
    choices={[
      { id: "شامل", name: "شامل" },
      { id: "تقييم", name: "تقييم" },
    ]}
  />,
];
// ---------------------------------------------------------------

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <TextField source="id" label="رقم" />
      <ReferenceField label="الطالب" source="student_id" reference="students">
        <FunctionField render={(record) => `${record.id} - ${record.name}`} />
      </ReferenceField>
      <ReferenceField label="الامتحان" source="exam_id" reference="exams">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="marks" label="الدرجة" />
      <ReferenceField label="الدرجة العظمى" source="exam_id" reference="exams">
        <FunctionField render={(record) => `${record.total_marks}`} />
      </ReferenceField>
    </SimpleShowLayout>
  );
};
// ---------------------------------------------------------------

const ListPage = (props) => (
  <List
    {...props}
    actions={<ScoresActions />}
    filter={
      role === "teacher"
        ? { teacher_id: user_id }
        : role === "student"
        ? { student_id: user_id }
        : false
    }
    filters={
      role === "teacher"
        ? TeacherFilters
        : role === "student"
        ? StudentFilters
        : OwnerAdminFilters
    }
    empty={<Empty />}
  >
    <Datagrid>
      <TextField source="id" label="رقم" />
      {role !== "student" ? (
        <ReferenceField
          label="الطالب"
          source="student_id"
          reference="students"
          link={false}
        >
          <FunctionField render={(record) => `${record.id} - ${record.name}`} />
        </ReferenceField>
      ) : (
        <></>
      )}
      {role !== "teacher" ? (
        <ReferenceField
          label="المدرس"
          source="exam_id"
          reference="exams"
          link={false}
        >
          <ReferenceField source="teacher_id" reference="teachers" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
      ) : (
        <></>
      )}
      <ReferenceField
        label="الامتحان"
        source="exam_id"
        reference="exams"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="النوع"
        source="exam_id"
        reference="exams"
        link={false}
      >
        <TextField source="type" />
      </ReferenceField>
      <ReferenceField
        label="الوصف"
        source="exam_id"
        reference="exams"
        link={false}
      >
        <TextField source="description" />
      </ReferenceField>
      <TextField source="marks" label="الدرجة" />
      <ReferenceField
        label="الدرجة العظمى"
        source="exam_id"
        reference="exams"
        link={false}
      >
        <FunctionField render={(record) => `${record.total_marks}`} />
      </ReferenceField>
      {role !== "student" ? (
        <Actions label="الاعدادات">
          <ShowButton label="عرض" />
          <EditButton label="تعديل" />
          <DeleteWithCustomConfirmButton
            title="تأكيد حذف!"
            content={DeleteConfirmContent} // your custom contents of delete confirm dialog
            label="حذف" // label of delete button (default: 'Delete')
            ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
            cancel="غلق" // label of cancel button (default: 'Cancel')
          />
        </Actions>
      ) : (
        <></>
      )}
    </Datagrid>
  </List>
);

// ---------------------------------------------------------------

export default ListPage;
