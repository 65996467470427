import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;
const user_id = storage?.id || null;

// ---------------------------------------------------------------

const CreatePage = (props) => {
  if (role !== "student") {
    return (
      <Create
        {...props}
        title="اضافة مادة"
        transform={
          role === "teacher"
            ? (data) => ({ ...data, teacher_id: user_id })
            : (data) => ({ ...data })
        }
        successMessage="تم إضافة الامتحان بنجاح"
      >
        <SimpleForm variant="outlined" redirect="list">
          <TextInput source="name" label="الاسم" validate={required("مطلوب")} />
          <ReferenceInput
            label="المادة الدراسية"
            source="course_id"
            reference="courses"
            perPage={10}
            sort={{ field: "name", order: "ASC" }}
            validate={required("المادة الدراسية مطلوبة")}
          >
            <AutocompleteInput
              optionText={(choice) => `${choice.name} - ${choice.level}`}
            />
          </ReferenceInput>
          {role !== "teacher" ? (
            <ReferenceInput
              label="المدرس"
              source="teacher_id"
              reference="teachers"
              perPage={10}
              sort={{ field: "name", order: "ASC" }}
              validate={required("مطلوب")}
            >
              <AutocompleteInput
                optionText={(choice) =>
                  `${choice.name} - ${choice.teacher.speciality}`
                }
              />
            </ReferenceInput>
          ) : (
            <></>
          )}
          <NumberInput
            source="total_marks"
            label="الدرجة العظمى"
            validate={required("مطلوب")}
          />
          <SelectInput
            source="type"
            label="النوع"
            choices={[
              { id: "شامل", name: "شامل" },
              { id: "تقييم", name: "تقييم" },
            ]}
            validate={required("مطلوب")}
          />
          <TextInput source="description" label="الوصف" />
        </SimpleForm>
      </Create>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default CreatePage;
