import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { validMoney } from "../../Utils/validations.js";

// ---------------------------------------------------------------

const CreatePage = (props) => (
  <Create
    {...props}
    title="اضافة مذكرة"
    transform={(data) => ({ ...data })}
    successMessage="تم إضافة المذكرة بنجاح"
  >
    <SimpleForm variant="outlined" redirect="list">
      <TextInput
        source="name"
        label="المذكرة"
        validate={required("اسم المذكرة مطلوب")}
      />
      <ReferenceInput
        label="المدرس"
        source="teacher_id"
        reference="teachers"
        perPage={10}
        sort={{ field: "name", order: "ASC" }}
        validate={required("مطلوب")}
      >
        <AutocompleteInput
          optionText={(choice) =>
            `${choice.name} - ${choice.teacher.speciality}`
          }
        />
      </ReferenceInput>
      <ReferenceInput
        label="المادة الدراسية"
        source="course_id"
        reference="courses"
        perPage={10}
        sort={{ field: "name", order: "ASC" }}
        validate={required("المادة الدراسية مطلوبة")}
      >
        <AutocompleteInput
          optionText={(choice) => `${choice.name} - ${choice.level}`}
        />
      </ReferenceInput>
      <TextInput source="cost" label="السعر" validate={validMoney} />
    </SimpleForm>
  </Create>
);

// ---------------------------------------------------------------

export default CreatePage;
