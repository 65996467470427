import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { toast } from "react-toastify";

// =================================================================

const UseMyProvider = () => {
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }

    /**
     *  add your own headers here
     *  // options.headers.set('X-Custom-Header', 'foobar');
     */

    return fetchUtils.fetchJson(url, options).catch((err) => {
      const error = err?.body;

      if (error?.sqlMessage) {
        toast.error(err?.body?.sqlMessage, { theme: "dark" });
      }

      toast.error(error?.message, { theme: "dark" });

      if (error.errors?.length) {
        error.errors.forEach((e) => toast.error(e, { theme: "dark" }));
      }
    });
  };

  const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

  
  return { dataProvider };
};

// =================================================================

export default UseMyProvider;
