import React, { createElement, useRef, useEffect, useState } from "react";
import classnames from "classnames";
import {
  Card,
  Avatar,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import authProvider from "../Providers/authProvider";
// ====================================================================

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    card: {
      minWidth: 500,
      marginTop: "1em",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: "2em",
    },
    input: {
      width: 400,
      margin: "10px 0px",
    },
    button: {
      height: "50px",
      margin: "35px 0px 0px 0px",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: "RaLogin" }
);

// ====================================================================

export default function Signup(props) {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    staticContext,
    backgroundImage,
    ...rest
  } = props;

  // ----------------------------------------------------

  const containerRef = useRef();
  const classes = useStyles(props);
  let backgroundImageLoaded = false;
  const [values, setValues] = useState({
    id: "",
    name: "",
    password: "",
    confirmPassword: "",
    role: "admin",
    phone: "",
    centerName: "",
    parent_phone: "",
    whats_app: "",
    level: "",
    speciality: "",
  });

  // ----------------------------------------------------

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // ----------------------------------------------------

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  // ----------------------------------------------------

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  // ----------------------------------------------------

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  // ----------------------------------------------------

  const onSubmit = async (e) => {
    e.preventDefault();

    if (values.password !== values.confirmPassword) {
      return alert("تاكيد كلمة السر غير متوافقة");
    }

    await authProvider.signup(values);
    // localStorage.setItem("user", JSON.stringify(newUser));
    window.location.href = "/#/login/";
  };

  // ----------------------------------------------------

  return (
    <div
      className={classnames(classes.main, className)}
      {...rest}
      ref={containerRef}
    >
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <h2>تسجيل حساب جديد</h2>

        {/* ======================================================= */}

        <form className={classes.form} onSubmit={onSubmit}>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
           كود المستخدم
            <TextField
              name="id"
              onChange={handleChange}
              value={values.id}
              className={classes.input}
              required
            />
          </InputLabel>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            الاسم
            <TextField
              name="name"
              onChange={handleChange}
              value={values.name}
              className={classes.input}
              required
            />
          </InputLabel>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            كلمه السر
            <TextField
              type="password"
              name="password"
              onChange={handleChange}
              value={values.password}
              className={classes.input}
              required
            />
          </InputLabel>
          {/* ------------------------------------------ */}
          <InputLabel className={classes.input}>
            تأكيد كلمة المرور
            <TextField
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              value={values.confirmPassword}
              className={classes.input}
              required
            />
          </InputLabel>
          {/* ------------------- ----------------------- */}
          <InputLabel className={classes.input}>
            الدور
            <Select
              name="role"
              onChange={handleChange}
              value={values.role}
              className={classes.input}
              required
            >
              <MenuItem value={"admin"}>مشرف</MenuItem>
              {/* <MenuItem value={"student"}>طالب</MenuItem>
                            <MenuItem value={"teacher"}>مدرس</MenuItem> */}
            </Select>
          </InputLabel>
          {values.role === "admin" ? (
            <>
              <InputLabel className={classes.input}>
                موبيل
                <TextField
                  name="phone"
                  onChange={handleChange}
                  value={values.phone}
                  className={classes.input}
                  required
                />
              </InputLabel>
              <InputLabel className={classes.input}>
                اسم السنتر
                <TextField
                  name="centerName"
                  onChange={handleChange}
                  value={values.centerName}
                  className={classes.input}
                  required
                />
              </InputLabel>
            </>
          ) : values.role === "student" ? (
            <>
              <InputLabel className={classes.input}>
                موبيل الطالب
                <TextField
                  name="phone"
                  onChange={handleChange}
                  value={values.phone}
                  className={classes.input}
                  required
                />
              </InputLabel>
              <InputLabel className={classes.input}>
                موبيل ولي الامر
                <TextField
                  name="parent_phone"
                  onChange={handleChange}
                  value={values.parent_phone}
                  className={classes.input}
                  required
                />
              </InputLabel>
              <InputLabel className={classes.input}>
                موبيل واتس اب
                <TextField
                  name="whats_app"
                  onChange={handleChange}
                  value={values.whats_app}
                  className={classes.input}
                  required
                />
              </InputLabel>
              <InputLabel className={classes.input}>
                المرحلة الدراسية
                <TextField
                  name="level"
                  onChange={handleChange}
                  value={values.level}
                  className={classes.input}
                  required
                />
              </InputLabel>
            </>
          ) : values.role === "teacher" ? (
            <>
              <InputLabel className={classes.input}>
                موبيل
                <TextField
                  name="phone"
                  onChange={handleChange}
                  value={values.phone}
                  className={classes.input}
                  required
                />
              </InputLabel>
              <InputLabel className={classes.input}>
                التخصص
                <TextField
                  name="speciality"
                  onChange={handleChange}
                  value={values.speciality}
                  className={classes.input}
                  required
                />
              </InputLabel>
            </>
          ) : null}
          <div className={classes.input}>
            <a href="/#/login">لديك حساب بالفعل !</a>
          </div>
          {/* ------------------------------------------ */}
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
          >
            تسجيل
          </Button>
        </form>
      </Card>
      {notification ? createElement(notification) : null}
    </div>
  );
}
