import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from "react-admin";
import { levels } from "../../Utils/selectValues.js";

import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const EditPage = (props) => {
  if (role !== "student") {
    return (
      <Edit
        {...props}
        transform={(data) => ({ ...data })}
        successMessage="تم تعديل البيانات بنجاح"
      >
        <SimpleForm variant="outlined">
          <TextInput
            source="name"
            label="الاسم"
            validate={required("اسم المادة مطلوب")}
          />
          <SelectInput
            source="level"
            label="الصف الدراسي"
            choices={levels}
            validate={required("الصف الدراسي مطلوب")}
          />
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default EditPage;
