import ListPage from "./scores.List";
import CreatePage from "./scores.Create";
import ShowPage from "./scores.Show";
import EditPage from "./scores.Edit";

// ---------------------------------------------------------------

const scores = {
  name: "scores",
  label: "درجات الامتحانات",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default scores;
