import ListPage from "./enrolments.List";
import CreatePage from "./enrolments.Create";
import EditPage from "./enrolments.Edit";

// ---------------------------------------------------------------

const enrolments = {
  name: "enrolments",
  label: "الحجوزات",
  list: ListPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default enrolments;
