export const levels = [
  { id: "الصف الأول الابتدائي", name: "الصف الأول الابتدائي" },
  { id: "الصف الثاني الابتدائي", name: "الصف الثاني الابتدائي" },
  { id: "الصف الثالث الابتدائي", name: "الصف الثالث الابتدائي" },
  { id: "الصف الرابع الابتدائي", name: "الصف الرابع الابتدائي" },
  { id: "الصف الخامس الابتدائي", name: "الصف الخامس الابتدائي" },
  { id: "الصف السادس الابتدائي", name: "الصف السادس الابتدائي" },
  { id: "الصف الأول الاعدادي", name: "الصف الأول الاعدادي" },
  { id: "الصف الثاني الاعدادي", name: "الصف الثاني الاعدادي" },
  { id: "الصف الثالث الاعدادي", name: "الصف الثالث الاعدادي" },
  { id: "الصف الأول الثانوي", name: "الصف الأول الثانوي" },
  { id: "الصف الثاني الثانوي", name: "الصف الثاني الثانوي" },
  { id: "الصف الثالث الثانوي", name: "الصف الثالث الثانوي" },
];

export const days = [
  { id: "السبت", name: "السبت" },
  { id: "الاحد", name: "الاحد" },
  { id: "الاثنين", name: "الاثنين" },
  { id: "الثلاثاء", name: "الثلاثاء" },
  { id: "الاربعاء", name: "الاربعاء" },
  { id: "الخميس", name: "الخميس" },
  { id: "الجمعة", name: "الجمعة" },
];
