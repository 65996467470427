import React, { useState } from "react";
import { MenuItemLink, getResources } from "react-admin";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import SubMenu from "./MySubMenu";

// Icons
import TodayIcon from "@material-ui/icons/Today";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import BookIcon from "@material-ui/icons/Book";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import BallotIcon from "@material-ui/icons/Ballot";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PostAddIcon from "@material-ui/icons/PostAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ChromeReaderModeRoundedIcon from "@material-ui/icons/ChromeReaderModeRounded";
import QueueRoundedIcon from "@material-ui/icons/QueueRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import HelpOutline from "@material-ui/icons/HelpOutline";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import RestoreIcon from "@material-ui/icons/Restore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

// =================================================================

const icons = {
  courses: <BookIcon />,
  teachers: <RecordVoiceOverIcon />,
  course_groups: <BallotIcon />,
};

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;
const user_id = storage?.id || null;

// =================================================================

export default function OwnerAdminMenu({ dense = false, onMenuClick }) {
  const classes = useStyles();
  //  ---------------------------------------

  const resources = useSelector(getResources);
  // const { store } = useContext(ReactReduxContext);

  //  ---------------------------------------

  const [state, setState] = useState({
    studentMenu: false,
    bookletsMenu: false,
    examsMenu: false,
  });

  //  ---------------------------------------

  const customResources = [
    "attendances",
    "centerProfile",
    "enrolments",
    "students",
    "flow",
    "booklets",
    "booklet_instances",
    "exams",
    "scores",
    "branches",
    "rooms",
    "timetables",
    "users",
    "centers",
  ];

  //  ---------------------------------------

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  //  ---------------------------------------

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  //  ---------------------------------------

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {/* ------------------------------------------------- */}

      <MenuItemLink
        to={{
          pathname: "/timetable",
          state: { _scrollToTop: true },
        }}
        primaryText="الجدول الزمني"
        leftIcon={<TodayIcon />}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/attendances/create",
          state: { _scrollToTop: true },
        }}
        primaryText="تسجيل الحضور"
        leftIcon={<HowToRegIcon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("studentMenu")}
        isOpen={state.studentMenu}
        name="الطلاب"
        icon={<GroupIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/students",
            state: { _scrollToTop: true },
          }}
          primaryText={"بيانات الطلاب"}
          leftIcon={<ContactPhoneIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/enrolments/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"حجز مادة"}
          leftIcon={<PostAddIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/enrolments",
            state: { _scrollToTop: true },
          }}
          primaryText={"حجوزات المواد"}
          leftIcon={<LibraryBooksIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/attendances",
            state: { _scrollToTop: true },
          }}
          primaryText={"سجل الحضور"}
          leftIcon={<RestoreIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/flow/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"إدارة المحفظة"}
          leftIcon={<AccountBalanceWalletIcon />}
          dense={dense}
        />
        {/*<MenuItemLink
              to={{
                pathname: "/print_id",
                state: { _scrollToTop: true },
              }}
              primaryText={"طباعة الكارنيهات"}
              leftIcon={<CreditCard />}
              dense={dense}
            />*/}
      </SubMenu>
      {/* ------------------------------------------------- */}
      {resources
        .filter((r) => !customResources.includes(r.name))
        .map((resource, index) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            resource={resource}
            // {...menuProps}
            leftIcon={icons[resource?.name]}
          />
        ))}
      {/* ------------------------------------------------- */}
      <SubMenu
        handleToggle={() => handleToggle("bookletsMenu")}
        isOpen={state.bookletsMenu}
        name="المذكرات"
        icon={<MenuBookIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/booklets",
            state: { _scrollToTop: true },
          }}
          primaryText={"بيانات المذكرات"}
          leftIcon={<ChromeReaderModeRoundedIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/booklet_instances/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"حجز مذكرة"}
          leftIcon={<QueueRoundedIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/booklet_instances",
            state: { _scrollToTop: true },
          }}
          primaryText={"حجوزات المذكرات"}
          leftIcon={<ListAltRoundedIcon />}
          dense={dense}
        />
      </SubMenu>
      {/* ------------------------------------------------- */}
      <SubMenu
        handleToggle={() => handleToggle("examsMenu")}
        isOpen={state.examsMenu}
        name="الامتحانات والتقييم"
        icon={<HelpOutline />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/exams/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"اضافة امتحان"}
          leftIcon={<QueueRoundedIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/exams",
            state: { _scrollToTop: true },
          }}
          primaryText={"بيانات الامتحانات"}
          leftIcon={<Assignment />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/scores/create",
            state: { _scrollToTop: true },
          }}
          primaryText={"تصحيح امتحان"}
          leftIcon={<AssignmentTurnedIn />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/scores",
            state: { _scrollToTop: true },
          }}
          primaryText={"درجات الامتحانات"}
          leftIcon={<ListAltRoundedIcon />}
          dense={dense}
        />
      </SubMenu>
      {/* ------------------------------------------------- */}
      {role === "owner" ? (
        <MenuItemLink
          to={{
            pathname: "/flow",
            state: { _scrollToTop: true },
          }}
          primaryText={"سجل الحسابات"}
          leftIcon={<AttachMoneyIcon />}
          dense={dense}
        />
      ) : (
        <></>
      )}
      {/* ------------------------------------------------- */}

      <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        name="الاعدادات"
        icon={<SettingsIcon />}
        dense={dense}
      >
        {/*<MenuItemLink
              to={{
                pathname: "/centerProfile",
                state: { _scrollToTop: true },
              }}
              primaryText={"الملف الشخصي"}
              customResources
              dense={dense}
              leftIcon={<AccountCircleRoundedIcon />}
            />*/}

        <MenuItemLink
          to={{
            pathname: "/users/" + user_id,
            state: { _scrollToTop: true },
          }}
          primaryText={"تغيير كلمة المرور"}
          customResources
          dense={dense}
          leftIcon={<VisibilityOffIcon />}
        />
        {role === "owner" ? (
          <>
            <MenuItemLink
              to={{
                pathname: "/centers/1/show",
                state: { _scrollToTop: true },
              }}
              primaryText={"رصيد المالك"}
              customResources
              dense={dense}
              leftIcon={<MonetizationOnIcon />}
            />
            <MenuItemLink
              to={{
                pathname: "/users",
                state: { _scrollToTop: true },
              }}
              primaryText={"المستخدمين"}
              customResources
              dense={dense}
              leftIcon={<GroupIcon />}
            />
            <MenuItemLink
              to={{
                pathname: "/branches",
                state: { _scrollToTop: true },
              }}
              primaryText={"الفروع"}
              customResources
              dense={dense}
              leftIcon={<AccountTreeIcon />}
            />
            <MenuItemLink
              to={{
                pathname: "/rooms",
                state: { _scrollToTop: true },
              }}
              primaryText={"القاعات"}
              customResources
              dense={dense}
              leftIcon={<MeetingRoomIcon />}
            />
          </>
        ) : (
          <></>
        )}
      </SubMenu>
    </div>
  );
}

// =================================================================

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

// =================================================================
