import React from "react";
import { Layout, Notification } from "react-admin";
import MyAppBar from "./MyAppBar.js";
import MySidebar from "./MySideBar.js";

// -----------------------------------------------------------

const MyNotification = (props) => (
  <Notification {...props} autoHideDuration={5000} />
);

// -----------------------------------------------------------

export default function MyLayout(props) {
  return (
    <Layout
      {...props}
      appBar={MyAppBar}
      sidebar={MySidebar}
      notification={MyNotification}
    />
  );
}
