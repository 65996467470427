import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  ReferenceField,
  NumberField,
  useListContext,
  DateInput,
  ReferenceInput,
  SelectInput,
  FilterButton,
  TopToolbar,
  AutocompleteInput,
  TextInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const Empty = () => {
  return (
    <Box textAlign="center" m={8}>
      <Typography variant="h5" paragraph>
        لا يوجد أي عمليات في الفترة المحددة
      </Typography>
    </Box>
  );
};

// ---------------------------------------------------------------

function income_expense(data) {
  let income = 0;
  let expense = 0;
  for (let i in data) {
    if (data[i].type === "مدخلات") {
      income += data[i].amount;
    } else if (data[i].type === "مخرجات") {
      expense += data[i].amount;
    }
  }
  return { income, expense };
}

// ---------------------------------------------------------------

const Aside = () => {
  const { data } = useListContext();
  const { income, expense } = income_expense(data);
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h5">الخزنة</Typography>
      <Typography variant="body1">في الفترة المحددة</Typography>
      <Typography variant="body1">
        مدخلات : {income.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " "}
        ج.م.
      </Typography>
      <Typography variant="body1">
        مخرجات : {expense.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " "}
        ج.م.
      </Typography>
      <Typography variant="body1">
        المبلغ المتاح :{" "}
        {(income - expense).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
          " "}
        ج.م.
      </Typography>
    </div>
  );
};

// ---------------------------------------------------------------

const FlowActions = ({ basePath, data }) => (
  <TopToolbar>
    {/*<ExportButton label="تصدير" />*/}
    {role === "owner" && <FilterButton label="اضف فلتر" />}
  </TopToolbar>
);

// ---------------------------------------------------------------

const studentChoice = (choice) => {
  if (choice?.id) {
    return choice.id + " - " + choice.name;
  } else {
    return "";
  }
};

const flowFilters = [
  <DateInput alwaysOn label="من تاريخ" source="from_date" />,
  <DateInput alwaysOn label="الى تاريخ" source="to_date" />,
  <TextInput source="query" label="بحث بالوصف" />,
  <ReferenceInput label="الفرع " source="branch_id" reference="branches">
    <SelectInput source="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="الطالب"
    source="student_id"
    reference="students"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={studentChoice} />
  </ReferenceInput>,
  <ReferenceInput
    label="الموظف"
    source="employee_id"
    reference="users"
    filter={{ role: ["admin", "owner"] }}
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText={studentChoice} />
  </ReferenceInput>,
];

// ---------------------------------------------------------------

const ListPage = (props) => {
  return role === "owner" || role === "student" ? (
    <List
      {...props}
      actions={<FlowActions />}
      bulkActionButtons={false}
      aside={role === "student" ? false : <Aside />}
      pagination={false}
      perPage={1000}
      filter={role === "student" ? { student_id: storage?.id } : false}
      filters={flowFilters}
      filterDefaultValues={
        role !== "student"
          ? {
              from_date: new Date().toDateString(),
              to_date: new Date().toDateString(),
            }
          : {
              from_date: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              ),
              to_date: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
              ),
            }
      }
    >
      <Datagrid empty={<Empty />}>
        <TextField source="id" label="كود العملية" />
        <FunctionField
          label="التاريخ"
          source="created_at"
          render={(record) => new Date(record.created_at).toLocaleDateString()}
        />
        <FunctionField
          label="الوقت"
          source="created_at"
          render={(record) => new Date(record.created_at).toLocaleTimeString()}
        />
        {role !== "student" && (
          <ReferenceField
            label="الفرع "
            source="branch_id"
            reference="branches"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {role !== "student" && (
          <ReferenceField
            label="اسم المُسلِّم (من)"
            source="from_id"
            reference="users"
            link="show"
            range={[0, 1000]}
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {role !== "student" && (
          <ReferenceField
            label="اسم المُستَلِم (الى)"
            source="to_id"
            reference="users"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {role !== "student" && <TextField source="type" label="نوع العملية" />}
        <NumberField
          source="amount"
          label="المبلغ"
          options={{ style: "currency", currency: "EGP" }}
        />
        <TextField source="description" label="الوصف" />
      </Datagrid>
    </List>
  ) : (
    <NotFound />
  );
};

// ---------------------------------------------------------------

export default ListPage;
