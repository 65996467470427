import * as React from "react";
import { Route } from "react-router-dom";
import Signup from "./Signup";
import TimeTable from "./TimeTable";
import PrintID from "./PrintID";

// ---------------------------------------------------

const customRoutes = [
  <Route path="/timetable" component={TimeTable} />,
  <Route path="/print_id" component={PrintID} />,
  <Route path="/create_a_new_user" component={Signup} noLayout />,
];

// ---------------------------------------------------

export default customRoutes;
