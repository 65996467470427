import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
  NumberField,
} from "react-admin";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const role = storage?.role || null;

// ---------------------------------------------------------------

const ShowPage = (props) => {
  if (role !== "student") {
    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="رقم" />
          <TextField source="name" label="اسم المجموعة" />
          <ReferenceField
            label="المدرس"
            source="teacher_id"
            reference="teachers"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="المادة الدراسية"
            source="course_id"
            reference="courses"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="الفرع "
            source="branch_id"
            reference="branches"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="payment_type" label="نوع الدفع" />
          <NumberField
            source="cost"
            label="التكلفة"
            options={{ style: "currency", currency: "EGP" }}
          />
          <TextField source="active" label="مفعل" />
        </SimpleShowLayout>
      </Show>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default ShowPage;
