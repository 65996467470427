import axios from "axios";

// -----------------------------------------------------------

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

// -----------------------------------------------------------

request.interceptors.request.use(async (config) => {
  // if (token) {
  //     config.headers["x-access-token"] = token;
  // } else {
  // }
  return config;
});

// -----------------------------------------------------------

export default request;
