import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  email,
} from "react-admin";
import { validPhone } from "../../Utils/validations.js";
import { levels } from "../../Utils/selectValues.js";
import NotFound from "../../CustomRoutes/NotFound";

// ---------------------------------------------------------------

const storage = JSON.parse(localStorage?.getItem("user"));
const branch_id = storage?.branch_id || null;
const role = storage?.role || null;

// ---------------------------------------------------------------

const EditPage = (props) => {
  if (role !== "student") {
    return (
      <Edit
        {...props}
        transform={(data) => ({ ...data, branch_id })}
        successMessage="تم تعديل البيانات بنجاح"
      >
        <SimpleForm variant="outlined">
          <TextInput source="name" label="الاسم" validate={required("مطلوب")} />
          <TextInput
            source="email"
            label="البريد الإلكتروني"
            validate={[
              required("مطلوب"),
              email("برجاء ادخال بريد إلكتروني صالح"),
            ]}
          />
          <TextInput
            source="student.phone"
            label="الهاتف"
            validate={validPhone}
          />
          <TextInput
            source="student.parent_phone"
            label="هاتف ولى الامر"
            validate={validPhone}
          />
          <TextInput
            source="student.whats_app"
            label="واتس اب"
            validate={validPhone}
          />
          <TextInput
            source="student.vodafone_cash"
            label="فودافون كاش"
            validate={validPhone}
          />
          <SelectInput
            source="student.level"
            label="الصف الدراسي"
            choices={levels}
          />
        </SimpleForm>
      </Edit>
    );
  } else {
    return <NotFound />;
  }
};

// ---------------------------------------------------------------

export default EditPage;
