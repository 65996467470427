import API from "../API/api.config.js";

// ====================================================

const signup = async (payload) => {
  try {
    const res = await API.post("/signup", payload);
    const data = await res.data;
    return data;
  } catch (e) {
    console.error("error in signup:");
    console.error("error message: ", e.message);
    console.error("error: ", e);
  }
};

// -----------------------------------------------------

const login = async (payload) => {
  try {
    const res = await API.post("/login", payload);
    const user = await res.data;
    const url = process.env.REACT_APP_API_URL;
    const centerRes = await fetch(url + "/centers/" + user?.center_id);
    const center = await centerRes.json();

    if (!user || !center) {
      return Promise.reject(alert("كود المستخدم او كلمة السر غير صحيح"));
    } else {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("center", JSON.stringify(center));
      return Promise.resolve(user);
    }
  } catch (e) {
    console.error("error in login:");
    console.error("error message: ", e.message);
    console.error("error: ", e);
    return Promise.reject(alert("خطاء فى الدخول"));
  }
};

// -----------------------------------------------------

const checkError = (error) => console.error("error", error);

// -----------------------------------------------------

const checkAuth = async () => {
  let user = JSON.parse(localStorage.getItem("user"));
  let url = window.location.hash;
  if (user?.password !== "OwnerDiab" && user?.id === 1) {
    logout();
    return Promise.reject();
  }
  if (url === "#/create_a_new_user") {
    return Promise.resolve();
  }

  if (url === "#/login") {
    return Promise.reject();
  }

  if (!user) {
    window.location.href = "/#/login";
    return Promise.reject();
  }

  // Authenticated
  if (user?.accessToken) {
    return Promise.resolve(user);
  }

  // Un-Authenticated
  localStorage.setItem("user", JSON.stringify(user));
  return Promise.reject();
};

// -----------------------------------------------------

const logout = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("center");
};

// -----------------------------------------------------

const getIdentity = () => {
  let user = JSON.parse(localStorage.getItem("user"));

  // Authenticated
  if (user?.accessToken) {
    return Promise.resolve(user?.role);
  }
  window.location.href = "/#/login";
  // window.location.href = "/#/signup";
  return Promise.reject();
};

// -----------------------------------------------------

const getPermissions = (params) => {
  const role = JSON.parse(localStorage.getItem("user"))?.role;
  return role ? Promise.resolve(role) : Promise.reject();
};

// =====================================================

let authProvider = {
  signup,
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  getPermissions,
};

// =====================================================

export default authProvider;
