import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  EditButton,
  SimpleShowLayout,
  TopToolbar,
  ReferenceField,
  FilterButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from "react-admin";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import Actions from "../../Components/Actions.js";

// ---------------------------------------------------------------

const EnrolmentsActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="حجز مادة" />
    <ExportButton label="تصدير" />
    <FilterButton />
  </TopToolbar>
);

// ---------------------------------------------------------------

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <TextField label="رقم الطالب" source="student_id" />
      <ReferenceField
        label="اسم الطالب"
        source="student_id"
        reference="students"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="المادة"
        source="group_id"
        reference="course_groups"
        link={false}
      >
        <ReferenceField source="course_id" reference="courses" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        label="المدرس"
        source="group_id"
        reference="course_groups"
        link={false}
      >
        <ReferenceField source="teacher_id" reference="teachers" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        label="المجموعة"
        source="group_id"
        reference="course_groups"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  );
};

// ---------------------------------------------------------------

const displayChoice = (choice) => {
  if (choice?.id) {
    return choice.id + " - " + choice.name;
  } else {
    return "";
  }
};

const filters = [
  <ReferenceInput
    label="الطالب"
    source="student_id"
    reference="students"
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText={displayChoice} />
  </ReferenceInput>,
  <ReferenceInput
    label="المجموعة"
    source="group_id"
    reference="course_groups"
    perPage={10000}
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

// ---------------------------------------------------------------

const ListPage = (props) => {
  return (
    <List {...props} actions={<EnrolmentsActions />} filters={filters}>
      <Datagrid>
        <ReferenceField
          label="المادة"
          source="group_id"
          reference="course_groups"
          link={false}
        >
          <ReferenceField source="course_id" reference="courses" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          label="المدرس"
          source="group_id"
          reference="course_groups"
          link={false}
        >
          <ReferenceField source="teacher_id" reference="teachers" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          label="المجموعة"
          source="group_id"
          reference="course_groups"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="الطالب"
          source="student_id"
          reference="students"
          link={false} //"show"
        >
          <TextField source="name" />
        </ReferenceField>
        {/*<TextField source="payment_type" label="نوع الطالب" />
        <NumberField
          source="lecture_cost"
          label="تكلفة الحصة"
          options={{ style: "currency", currency: "EGP" }}
        />
        <NumberField
          source="center_cost_per_lecture"
          label="تكلفة السنتر"
          options={{ style: "currency", currency: "EGP" }}
        />*/}
        <Actions label="الاعدادات">
          <EditButton label="تغيير مجموعة" />
          <DeleteWithCustomConfirmButton
            title="تأكيد حذف الطالب من المجموعة!"
            content={DeleteConfirmContent} // your custom contents of delete confirm dialog
            label="حذف من المجموعة " // label of delete button (default: 'Delete')
            ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
            cancel="غلق" // label of cancel button (default: 'Cancel')
          />
        </Actions>
      </Datagrid>
    </List>
  );
};

// ---------------------------------------------------------------

export default ListPage;
