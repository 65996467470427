import { Show, TextField, SimpleShowLayout, ReferenceField } from "react-admin";

// ---------------------------------------------------------------

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="رقم" />
      <ReferenceField
        label="المدرس"
        source="teacher_id"
        reference="teachers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="المادة"
        source="course_id"
        reference="courses"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="المذكرة" />
      <TextField source="cost" label="السعر" />
    </SimpleShowLayout>
  </Show>
);

// ---------------------------------------------------------------

export default ShowPage;
