import ListPage from "./exams.List";
import CreatePage from "./exams.Create";
import EditPage from "./exams.Edit";
import ShowPage from "./exams.Show";

// ---------------------------------------------------------------

const exams = {
  name: "exams",
  label: "الامتحانات و التقييم",
  list: ListPage,
  show: ShowPage,
  create: CreatePage,
  edit: EditPage,
};

// ---------------------------------------------------------------

export default exams;
