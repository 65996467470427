import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// ---------------------------------------------------------------

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  balance: {
    backgroundColor: "#F9E79F",
    padding: 4,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    borderRadius: 5,
    textAlign: "center",
    color: "black",
  },
});

// ---------------------------------------------------------------

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} style={{ marginBottom: 40, position: "fixed" }}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};

// ---------------------------------------------------------------

export default MyAppBar;
