import React, { useEffect, useContext } from "react";
import { Admin, Resource } from "react-admin";
import UseMyProvider from "../Providers/dataProvider";
import authProvider from "../Providers/authProvider";
import entities from "../Entities/_entities";
import customRoutes from "../CustomRoutes/_CustomRoutes";
//import Dashboard from "../Dashboard/Dashboard";
import NotFound from "../CustomRoutes/NotFound";
import Login from "../CustomRoutes/Login";
import permissions from "../Constants/permissions";
import MyLayout from "./MyLayout";
import roleContext from "../Providers/roleContext";
import myTheme from "./myTheme";
import MyMenu from "./MyMenu";

// ===========================================================================

export default function MyAdmin() {
  const { role, setRole } = useContext(roleContext);

  useEffect(() => {
    let { role: AuthenticatedRole } =
      JSON.parse(localStorage.getItem("user")) || "guest";
    setRole(AuthenticatedRole);
  }, [setRole]);

  const { dataProvider } = UseMyProvider();

  return (
    <div>
      <Admin
        //dashboard={Dashboard}
        layout={MyLayout}
        loginPage={Login}
        menu={MyMenu}
        dataProvider={dataProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        theme={myTheme}
        catchAll={NotFound}
      >
        {entities
          ?.filter((e) => permissions[role]?.includes(e?.name))
          ?.map(({ label, ...rest }, index) => (
            <Resource options={{ label }} key={index} {...rest} />
          ))}
      </Admin>
    </div>
  );
}
