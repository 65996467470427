import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  ShowButton,
  EditButton,
  SimpleShowLayout,
  TopToolbar,
  ReferenceField,
} from "react-admin";
import Actions from "../../Components/Actions.js";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";

// ---------------------------------------------------------------

const BookletsActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} label="اضف مذكرة" />
    <ExportButton label="تصدير" />
  </TopToolbar>
);
// ---------------------------------------------------------------

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="رقم" />
        <ReferenceField
          label="المدرس"
          source="teacher_id"
          reference="teachers"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="المادة"
          source="course_id"
          reference="courses"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" label="المذكرة" />
        <TextField source="cost" label="السعر" />
      </SimpleShowLayout>
    </SimpleShowLayout>
  );
};
// ---------------------------------------------------------------

const ListPage = (props) => (
  <List {...props} actions={<BookletsActions />} filter={{ active: 1 }}>
    <Datagrid>
      <TextField source="id" label="رقم" />
      <ReferenceField
        label="المدرس"
        source="teacher_id"
        reference="teachers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="المادة"
        source="course_id"
        reference="courses"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="المذكرة" />
      <TextField source="cost" label="السعر" />
      <Actions label="الاعدادات">
        <ShowButton label="عرض" />
        <EditButton label="تعديل" />
        <DeleteWithCustomConfirmButton
          title="تأكيد حذف مذكرة"
          content={DeleteConfirmContent} // your custom contents of delete confirm dialog
          label="حذف" // label of delete button (default: 'Delete')
          ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
          cancel="غلق" // label of cancel button (default: 'Cancel')
        />
      </Actions>
    </Datagrid>
  </List>
);

// ---------------------------------------------------------------

export default ListPage;
