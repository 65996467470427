import { Show, TextField, SimpleShowLayout, ReferenceField } from "react-admin";

// ---------------------------------------------------------------

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        label="الفرع"
        source="branch_id"
        reference="branches"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="اسم القاعة" />
      <TextField source="max_capacity" label="اقصى سعة" />
    </SimpleShowLayout>
  </Show>
);

// ---------------------------------------------------------------

export default ShowPage;
